import {useCallback, useRef, useState} from 'react';
import {PrinterIcon} from '@heroicons/react/24/solid';
import {Button} from '../../Components/Tailwind';
import {LoadingIndicator} from '../../Components/Tailwind/LoadingIndicator';
import {Event, Participant} from '../../db/db';
import {HandleError} from '../../hooks/useError';
import {createBadge} from '../../utils/client';
import {b64toBlob} from '../registration/badge';

export function ParticipantAccessArea({
  event,
  participant,
}: {
  event: Event;
  participant: Participant;
  handleError: HandleError;
}) {
  return (
    <div className="align-center flex flex-row flex-wrap justify-center gap-1 text-center">
      {event &&
        event.accessZones &&
        event.accessZones.map(zone => {
          const isSelected =
            participant.accessZones &&
            participant.accessZones[zone.uuid] &&
            participant.accessZones[zone.uuid].access === true;
          return (
            <div
              key={`${participant.uuid}-zone-${zone.uuid}`}
              className={`x-5 mb-2 me-2 rounded-full px-5 py-2.5 text-center 
              text-sm font-medium  ${isSelected ? 'bg-blue-600 text-white dark:bg-blue-600' : 'border border-gray-300 text-gray-900 dark:border-gray-600 dark:bg-gray-800 dark:text-white'}`}
            >
              {zone.label}
            </div>
          );
        })}
    </div>
  );
}

export function ParticipantTagsArea({
  event,
  participant,
}: {
  event: Event;
  participant: Participant;
  handleError: HandleError;
}) {
  return (
    <div className="align-center flex flex-row flex-wrap justify-center gap-1 text-center">
      {event &&
        event.participantTags &&
        participant.tags !== undefined &&
        event.participantTags
          .filter(tag => participant.tags && participant.tags[tag.id] === true)
          .map(tag => (
            <div
              key={`${participant.uuid}-zone-${tag.id}`}
              className="x-5 mb-2 me-2 rounded-full bg-green-500 px-5 py-2.5 text-center text-sm font-medium text-white dark:bg-green-600"
            >
              {tag.label}
            </div>
          ))}
    </div>
  );
}

export function ParticipantBadgeArea({
  event,
  participant,
  handleError,
}: {
  event: Event;
  participant: Participant;
  handleError: HandleError;
}) {
  const iFrameRef = useRef<HTMLIFrameElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const onClick = useCallback(
    async (templateId?: number) => {
      // indicate loading
      setIsLoading(true);

      // print badge for first registration
      if (participant.registrations && participant.registrations.length > 0) {
        // get url
        const response = await createBadge(
          {
            serverId: event.serverId,
            eventId: event.indicoId,
            regformId: participant.registrations[0].regformId,
            registrationId: String(participant.registrations[0].id),
          },
          templateId
        );

        // check response
        if (response.ok) {
          try {
            console.log('Fetching worked', response);
            const blob = b64toBlob(response.data.document, 'application/pdf');
            const url = window.URL.createObjectURL(blob);
            if (iFrameRef.current && navigator && navigator.pdfViewerEnabled) {
              const frame = iFrameRef.current;
              frame.style.display = 'none';
              frame.onload = () => {
                setTimeout(() => {
                  frame.focus();
                  console.log('print started');
                  if (frame.contentWindow === null) {
                    // open in new tab
                    window.open(url, '_blank');
                  } else {
                    frame.contentWindow.print();
                  }
                }, 1);
              };
              frame.src = url;
            } else {
              // open in new tab
              window.open(url, '_blank');
            }
          } catch {
            console.log('Error while printing', response);
            handleError({}, `Please visit ${response.data.downloadUrl} to print the badge.`);
          }
        } else {
          console.log('Error while fetching', response);

          handleError(
            response,
            response.err
              ? response.err.message
              : `Response status: ${response.status ? response.status : 'No response received'}`
          );
        }
      } else {
        handleError({}, `There is no registration to print a badges for.`);
      }

      // loading finished
      setIsLoading(false);
      return;
    },
    [event, participant, handleError, setIsLoading]
  );
  return (
    <div
      className="rounded-xl border border-cyan-300 bg-cyan-100 p-4 text-sm text-cyan-800 dark:border-cyan-800 dark:border-cyan-800 dark:bg-gray-800 dark:text-cyan-300"
      role="alert"
    >
      <div className="w-100 text-center">Print a badge for this participant</div>
      {isLoading ? (
        <div className="mt-4">
          <LoadingIndicator />
        </div>
      ) : (
        <div className="mt-4 flex flex-wrap justify-center gap-4">
          <Button variant="default" onClick={() => onClick()}>
            <PrinterIcon className="h-5 w-5" />
            Print Badge
          </Button>
        </div>
      )}
      <iframe ref={iFrameRef} height={0} width={200} title="Print badge" />
    </div>
  );
}
