import {useEffect, useState} from 'react';
import db, {
  DesignerTemplate,
  EventAccessZone,
  EventRegform,
  EventRoom,
  EventTag,
  ParticipantRegistration,
  ParticipantToAccessZone,
  ParticipantToTag,
  RegistrationData,
  RegistrationState,
} from '../db/db';
import {camelizeKeys} from './case';

export const useIsOffline = () => {
  const [offline, setOffline] = useState(!navigator.onLine);

  useEffect(() => {
    const onOnline = () => setOffline(false);
    const onOffline = () => setOffline(true);

    window.addEventListener('online', onOnline);
    window.addEventListener('offline', onOffline);

    return () => {
      window.removeEventListener('online', onOnline);
      window.removeEventListener('offline', onOffline);
    };
  }, []);

  return offline;
};

export interface IndicoEvent {
  id: number;
  title: string;
  description?: string;
  startDt: string;
  endDt: string;
  participantTags: EventTag[];
  templates: DesignerTemplate[];
  accessZones: EventAccessZone[];
  rooms: EventRoom[];
  regforms: EventRegform[];
  occupancy?: {in: number; out: number};
}
export interface IndicoRegform {
  id: number;
  eventId: number;
  title: string;
  introduction?: string;
  startDt?: string;
  endDt?: string;
  registrationCount: number;
  checkedInCount: number;
  isOpen: boolean;
}

interface _IndicoRegistration {
  id: string;
  hashId: string;
  regformId: number;
  eventId: number;
  fullName: string;
  company: string;
  token: string;
  email: string;
  state: RegistrationState;
  registrationDate: string;
  checkedIn: boolean;
  checkedInDt?: string;
  checkinSecret: string;
  occupiedSlots: number;
  registrationData: RegistrationData[];
  tags: string[];
  price: number;
  currency: string;
  formattedPrice: string;
  isPaid: boolean;
}

export interface IndicoRegistration extends _IndicoRegistration {
  registrationData: RegistrationData[];
}

// Returned for /forms/:regformId/registrations/
// The registration data is not needed to display the list of registrations
// and it saves a lot of bandwidth to not include it
export type IndicoRegistrationList = _IndicoRegistration[];

export interface IndicoParticipant {
  uuid: string;
  email: string;
  salutation?: string;
  title?: string;
  firstName?: string;
  lastName?: string;
  position?: string;
  company?: string;
  tags?: ParticipantToTag;
  accessZones?: ParticipantToAccessZone;
  registrations?: ParticipantRegistration[];
  registrationDate?: string;
  checkedIn: boolean;
  checkedInDt?: string;
}

// Returned form /participants
export type IndicoParticipantsList = IndicoParticipant[];

export interface IndicoBadgeScan {
  eventId: number;
  uuid: string;
  accessGranted: boolean;
  logout: boolean;
  reason?: string;
  reasonLabel?: string;
  accessZoneUuid?: string;
  accessZoneLabel?: string;
  roomUuid?: string;
  roomLabel?: string;
  participant: IndicoParticipant;
}

interface EventLocator {
  serverId: number;
  eventId: number;
}

interface RegformLocator extends EventLocator {
  regformId: number;
}

interface RegistrationLocator extends RegformLocator {
  registrationId: string;
}

interface ParticipantLocator extends EventLocator {
  participantUuid: string;
}
interface BadgeLocator extends EventLocator {
  badgeUuid: string;
}
export interface BadgeValidationParameters {
  logout: boolean;
  accessZoneUuid?: string;
  roomUuid?: string;
}

interface SuccessfulResponse<T> {
  ok: true;
  status: number;
  data: T;
}

export interface FailedResponse {
  ok: false;
  status?: number;
  aborted?: boolean;
  network?: boolean;
  err?: any;
  endpoint?: string;
  options?: object;
  data?: any;
  description?: string;
}

type Response<T> = SuccessfulResponse<T> | FailedResponse;

async function makeRequest<T>(
  serverId: number,
  endpoint: string,
  options: object = {}
): Promise<Response<T>> {
  const server = await db.servers.get(serverId);
  if (!server) {
    return {
      ok: false,
      endpoint,
      options,
      description: `Server (id <${serverId}>) not found in IndexedDB`,
    };
  }

  const url = new URL(endpoint, server.baseUrl);
  let response;
  try {
    response = await fetch(url, {
      ...options,
      headers: {
        'Authorization': `Bearer ${server.authToken}`,
        'Content-Type': 'application/json',
      },
    });
  } catch (err: any) {
    if (err instanceof DOMException && err.name === 'AbortError') {
      // Ignore cancelled requests
      return {ok: false, aborted: true};
    }
    // Assume everything else is a network issue (impossible to distinguish from other TypeErrors)
    return {ok: false, network: true, endpoint, options, err};
  }

  let data;
  try {
    data = await response.json();
  } catch (err) {
    if (err instanceof DOMException && err.name === 'AbortError') {
      // Ignore cancelled requests
      return {ok: false, aborted: true};
    }
    return {ok: false, endpoint, options, err, description: 'response.json() failed'};
  }

  if (!response.ok) {
    return {ok: false, status: response.status, endpoint, options, data};
  }

  data = camelizeKeys(data);
  return {ok: true, status: response.status, data};
}

export async function getEvent({serverId, eventId}: EventLocator, options?: object) {
  return makeRequest<IndicoEvent>(serverId, `api/checkin/event/${eventId}/`, options);
}

export async function getRegforms({serverId, eventId}: EventLocator, options?: object) {
  return makeRequest<IndicoRegform[]>(serverId, `api/checkin/event/${eventId}/forms/`, options);
}

export async function getRegform({serverId, eventId, regformId}: RegformLocator, options?: object) {
  return makeRequest<IndicoRegform>(
    serverId,
    `api/checkin/event/${eventId}/forms/${regformId}/`,
    options
  );
}

export async function getRegistrations(
  {serverId, eventId, regformId}: RegformLocator,
  options?: object
) {
  return makeRequest<IndicoRegistrationList>(
    serverId,
    `api/checkin/event/${eventId}/forms/${regformId}/registrations/`,
    options
  );
}

export async function getRegistration(
  {serverId, eventId, regformId, registrationId}: RegistrationLocator,
  options?: object
) {
  return makeRequest<IndicoRegistration>(
    serverId,
    `api/checkin/event/${eventId}/forms/${regformId}/registrations/${registrationId}`,
    options
  );
}

export async function getParticipants({serverId, eventId}: EventLocator, options?: object) {
  return makeRequest<IndicoParticipantsList>(
    serverId,
    `api/checkin/event/${eventId}/participants`,
    options
  );
}

export async function getParticipant(
  {serverId, eventId, participantUuid}: ParticipantLocator,
  options?: object
) {
  return makeRequest<IndicoParticipant>(
    serverId,
    `api/checkin/event/${eventId}/participants/${participantUuid}`,
    options
  );
}

export async function getRegistrationByUuid(
  {serverId, uuid}: {serverId: number; uuid: string},
  options?: object
) {
  return makeRequest<IndicoRegistration>(serverId, `api/checkin/ticket/${uuid}`, options);
}

export async function validateBadge(
  {serverId, eventId, badgeUuid}: BadgeLocator,
  validationParams: BadgeValidationParameters
) {
  let data: any = validationParams;
  data['badgeUuid'] = badgeUuid;
  if (data['roomUuid'] === 'None') {
    data['roomUuid'] = undefined;
  }
  if (data['accessZoneUuid'] === 'None') {
    data['accessZoneUuid'] = undefined;
  }

  return makeRequest<IndicoBadgeScan>(serverId, `api/checkin/event/${eventId}/scan`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export async function checkInIndicoRegistration(
  {serverId, eventId, regformId, registrationId}: RegistrationLocator,
  checkInState: boolean
) {
  return makeRequest<IndicoRegistration>(
    serverId,
    `api/checkin/event/${eventId}/forms/${regformId}/registrations/${registrationId}`,
    {
      method: 'PATCH',
      body: JSON.stringify({checked_in: checkInState}),
    }
  );
}

export async function checkInIndicoParticipant(
  {serverId, eventId, participantUuid}: ParticipantLocator,
  checkInState: boolean
) {
  return makeRequest<IndicoRegistration>(
    serverId,
    `api/checkin/event/${eventId}/participants/${participantUuid}`,
    {
      method: 'PATCH',
      body: JSON.stringify({checked_in: checkInState}),
    }
  );
}

export async function togglePayment(
  {serverId, eventId, regformId, registrationId}: RegistrationLocator,
  paid: boolean
) {
  return makeRequest<IndicoRegistration>(
    serverId,
    `api/checkin/event/${eventId}/forms/${regformId}/registrations/${registrationId}`,
    {
      method: 'PATCH',
      body: JSON.stringify({paid}),
    }
  );
}

export interface BadgeDownloadUrl {
  downloadUrl: string;
  document: string;
}

export async function createBadge(
  {serverId, eventId, regformId, registrationId}: RegistrationLocator,
  templateId?: number
) {
  return makeRequest<BadgeDownloadUrl>(
    serverId,
    `api/checkin/event/${eventId}/forms/${regformId}/registrations/${registrationId}/badge`,
    {
      method: 'POST',
      body: templateId ? JSON.stringify({template_id: templateId}) : '{}',
    }
  );
}
