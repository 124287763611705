const patterns = {
  error: [100, 50, 100],
  success: [250],
  clear: [],
};

// see https://developer.mozilla.org/en-US/docs/Web/API/UserActivation
// // for whatever reason this throws an error in vs code, but not while building
// declare global {
//   interface Navigator {
//     userActivation: {
//       isActive: boolean;
//       hasBeenActive: boolean;
//     };
//   }
// }

function vibrate(pattern: number[]) {
  if (!('vibrate' in navigator) || !navigator['userActivation'].isActive) {
    console.warn('Haptics not supported!');
    return;
  }

  navigator.vibrate(pattern);
}

export const playVibration = {
  error: () => vibrate(patterns.error),
  success: () => vibrate(patterns.success),
  clear: () => vibrate(patterns.clear), // clear the vibration pattern (if needed)
};
