import {ChangeEvent, useState, useMemo, useRef, useCallback, useEffect, forwardRef} from 'react';
import {
  ArrowSmallLeftIcon,
  BanknotesIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  MagnifyingGlassIcon,
  UserGroupIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import {Event, Registration, Regform, getRegform} from '../../db/db';
import {useHandleError} from '../../hooks/useError';
import {useErrorModal} from '../../hooks/useModal';
import useSettings from '../../hooks/useSettings';
import {checkInRegistration} from '../../pages/Events/checkin';
import {useIsOffline} from '../../utils/client';
import {
  Filters,
  RegistrationFilters,
  RegistrationState,
  ResultCount,
  ToggleFiltersButton,
  isDefaultFilterState,
  makeDefaultFilterState,
} from './filters';
import {CheckinToggle} from './Toggle';
import Typography from './Typography';
import styles from './Table.module.scss';

const ROW_HEIGHT_PX = 56;

export interface SearchData {
  searchValue: string;
  filters: Filters;
}

export function TableFilters({
  searchData,
  setSearchData,
  resultCount,
}: {
  searchData: SearchData;
  setSearchData: (data: SearchData) => void;
  resultCount: number;
}) {
  const [filtersVisible, setFiltersVisible] = useState(false);
  const {filters, searchValue} = searchData;
  const filtersActive = searchValue !== '' || !isDefaultFilterState(filters);

  const setFilters = (f: Filters) => setSearchData({...searchData, filters: f});
  const setSearchValue = (v: string) => setSearchData({...searchData, searchValue: v});
  const resetSearchData = () => setSearchData({searchValue: '', filters: makeDefaultFilterState()});

  return (
    <>
      <div className="flex gap-2 px-4 pb-2 pt-4">
        <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} />
        <ToggleFiltersButton
          defaultState={isDefaultFilterState(filters)}
          filtersVisible={filtersVisible}
          onClick={() => setFiltersVisible(v => !v)}
        />
      </div>
      {filtersVisible && (
        <div className="px-4 pb-2">
          <RegistrationFilters
            filters={filters}
            setFilters={setFilters}
            onClose={() => setFiltersVisible(false)}
          />
        </div>
      )}
      {filtersActive && (
        <div className="mb-4 mt-2">
          <ResultCount count={resultCount} onClick={resetSearchData} />
        </div>
      )}
    </>
  );
}

export default function Table({
  registrations,
  event,
  regform,
  searchData,
  setSearchData: _setSearchData,
  onRowClick,
}: {
  registrations: Registration[];
  event: Event;
  regform?: Regform;
  searchData: SearchData;
  setSearchData: (data: SearchData) => void;
  onRowClick: (p: Registration) => void;
}) {
  const defaultVisibleRegistrations = getNumberVisibleRegistrations();
  const [numberVisibleRegistrations, setNumberVisibleRegistrations] = useState(
    defaultVisibleRegistrations
  );
  const dummyRowRef = useRef<HTMLTableRowElement>(null);

  const setSearchData = (data: SearchData) => {
    _setSearchData(data);
    setNumberVisibleRegistrations(defaultVisibleRegistrations);
  };

  const filteredRegistrations = useMemo(
    () => filterRegistrations(registrations, searchData),
    [registrations, searchData]
  );
  const dummyRowHeight =
    Math.max(filteredRegistrations.length - numberVisibleRegistrations, 0) * ROW_HEIGHT_PX;

  const rows = filteredRegistrations
    .slice(0, numberVisibleRegistrations)
    .map((p, i) => (
      <Row
        key={p.id}
        hashId={p.hashId}
        fullName={p.fullName}
        company={p.company}
        checkedIn={p.checkedIn}
        state={p.state}
        event={event}
        regform={regform}
        registration={p}
        isEven={i % 2 === 0}
        onClick={() => onRowClick(p)}
      />
    ));

  /**
   * Check if the dummy row is within 200vh of the top of the screen
   */
  function shouldLoadMore(): boolean {
    if (!dummyRowRef.current) {
      return false;
    }
    const top = dummyRowRef.current.getBoundingClientRect().top;
    return top < 2 * window.innerHeight;
  }

  function getNumberVisibleRegistrations() {
    const scroll = document.documentElement.scrollTop;
    // Load 5 additional screen heights worth of registrations
    const padded = scroll + 5 * window.innerHeight;
    return Math.ceil(padded / ROW_HEIGHT_PX);
  }

  useEffect(() => {
    function onScroll() {
      if (shouldLoadMore()) {
        setNumberVisibleRegistrations(getNumberVisibleRegistrations());
      }
    }

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [filteredRegistrations.length]);

  return (
    <div>
      <TableFilters
        searchData={searchData}
        setSearchData={setSearchData}
        resultCount={filteredRegistrations.length}
      />
      <div className="mx-4 mt-2">
        {rows.length === 0 && (
          <div className="mt-10 flex flex-col items-center justify-center rounded-xl">
            <div className="w-24 text-gray-500">
              <UserGroupIcon />
            </div>
          </div>
        )}
        <table className="w-full overflow-hidden rounded-xl text-left text-sm text-gray-500 dark:text-gray-400">
          <tbody>
            {rows}
            <DummyRow height={dummyRowHeight} ref={dummyRowRef} />
          </tbody>
        </table>
      </div>
    </div>
  );
}

function compareNames(a: string, b: string): number {
  return a.localeCompare(b);
}

function compareDefault(a: any, b: any): number {
  if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  } else {
    return 0;
  }
}
/**
 * Dummy row to artificially increase the height of the registration table.
 * This keeps the table height constant as more registrations become visible
 * while scrolling down and keeps the scroll bar from jumping around.
 */
const DummyRow = forwardRef(function DummyRow(
  {height}: {height: number},
  ref: React.Ref<HTMLTableRowElement>
) {
  return (
    <tr className="block bg-gray-200 dark:bg-gray-800" style={{height}} ref={ref}>
      <td></td>
    </tr>
  );
});

function filterRegistrations(registrations: Registration[], data: SearchData) {
  const {searchValue, filters} = data;

  return registrations
    .filter(p => {
      let checkedInValues = [];
      if (filters.checkedIn.yes) {
        checkedInValues.push(true);
      }
      if (filters.checkedIn.no) {
        checkedInValues.push(false);
      }

      const stateValues = Object.entries(filters.state)
        .filter(([, v]) => v)
        .map(([k]) => k);

      return (
        (checkedInValues.length === 0 || checkedInValues.includes(p.checkedIn)) &&
        (stateValues.length === 0 || stateValues.includes(p.state)) &&
        p.fullName.toLowerCase().includes(searchValue)
      );
    })
    .sort((a, b) => {
      const {key, ascending} = filters.sortBy;
      if (!ascending) {
        [b, a] = [a, b];
      }
      if (key === 'fullName') {
        return compareNames(a.fullName, b.fullName);
      } else {
        return compareDefault(a[key], b[key]);
      }
    });
}

interface RowProps {
  fullName: string;
  hashId: string;
  company: string;
  checkedIn: boolean;
  event: Event;
  regform?: Regform;
  registration: Registration;
  state: RegistrationState;
  onClick: () => void;
  isEven: boolean;
}

function Row({
  fullName,
  hashId,
  company,
  checkedIn,
  event,
  regform,
  registration,
  state,
  onClick,
  isEven,
}: RowProps) {
  const background: HTMLElement['className'] = isEven
    ? 'bg-gray-200 dark:bg-gray-800 active:bg-gray-300 dark:active:bg-gray-600'
    : 'bg-gray-100 dark:bg-gray-700 active:bg-gray-300 dark:active:bg-gray-600';

  const fullNameClass = 'select-none overflow-x-hidden text-ellipsis';

  const {soundEffect, hapticFeedback} = useSettings();
  const offline = useIsOffline();
  const errorModal = useErrorModal();
  const handleError = useHandleError();

  const performCheckin = useCallback(
    async (
      event: Event,
      regform: Regform,
      registration: Registration,
      newCheckinState: boolean
    ) => {
      if (offline) {
        errorModal({title: 'You are offline', content: 'Check-in requires an internet connection'});
        return;
      }

      try {
        await checkInRegistration(
          event,
          regform,
          registration,
          newCheckinState,
          soundEffect,
          hapticFeedback,
          handleError
        );
      } catch (err: any) {
        errorModal({title: 'Could not update check-in status', content: err.message});
      } finally {
      }
    },
    [offline, errorModal, handleError, soundEffect, hapticFeedback]
  );

  const onCheckInToggle = async () => {
    if (!event || !registration) {
      return;
    }

    if (offline) {
      errorModal({title: 'You are offline', content: 'Check-in requires an internet connection'});
      return;
    }
    if (regform) {
      await performCheckin(event, regform, registration, !registration.checkedIn);
    } else {
      const _regform = await getRegform({id: registration.regformId, eventId: event.id});
      if (_regform) {
        await performCheckin(event, _regform, registration, !registration.checkedIn);
      } else {
        errorModal({
          title: 'Registration form unavailable',
          content: 'Check-in not possible, because registration form is currently unavailable',
        });
      }
    }
  };

  return (
    <tr
      style={{WebkitTapHighlightColor: 'transparent'}}
      className={`${background} max-h-[${ROW_HEIGHT_PX}px] cursor-pointer select-none
                  active:bg-gray-300 active:transition-all dark:active:bg-gray-600`}
      onClick={onClick}
    >
      <td className="max-w-0 p-4">
        <div className="flex items-center justify-between">
          <div className="flex flex-col justify-center md:flex-row md:items-center ">
            <Typography
              variant="body1"
              className={
                state === 'rejected' || state === 'withdrawn'
                  ? `${fullNameClass} line-through`
                  : fullNameClass
              }
            >
              <strong style={{minWidth: '5rem', display: 'inline-block'}}>#{hashId}</strong>{' '}
              {fullName}
            </Typography>
            {company && (
              <Typography
                variant="body1"
                className={`mt-2 w-fit rounded-full bg-cyan-100 px-2.5 py-0.5 text-xs font-medium text-cyan-800 dark:bg-cyan-900 dark:text-cyan-300 md:ms-2 md:mt-1 ${
                  state === 'rejected' || state === 'withdrawn'
                    ? 'select-none line-through'
                    : 'select-none '
                }`}
              >
                {company}
              </Typography>
            )}
          </div>

          <div className="flex items-center">
            {state === 'pending' && (
              <ExclamationCircleIcon className="ms-2 h-6 w-6 text-yellow-500" />
            )}
            {state === 'unpaid' && <BanknotesIcon className="ms-2 h-6 w-6 text-yellow-500" />}
            {checkedIn && <CheckCircleIcon className="ms-2 h-6 w-6 text-green-500" />}
            {!checkedIn && (
              <div
                className="ms-2 rounded-full bg-gray-50 px-1 pt-1 dark:bg-gray-900"
                onClick={e => {
                  // prevent onClick event of row from firing
                  e.stopPropagation();
                }}
              >
                <CheckinToggle
                  checked={checkedIn}
                  isLoading={!!registration.checkedInLoading}
                  onClick={onCheckInToggle}
                  size="xs"
                />
              </div>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
}

function SearchInput({
  searchValue,
  setSearchValue,
}: {
  searchValue: string;
  setSearchValue: (v: string) => void;
}) {
  const [searchFocused, setSearchFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value.toLowerCase());
  };

  const onKeyUp = (e: any) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  const clearSearch = () => {
    setSearchValue('');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className="relative grow">
      {searchFocused && <LoseFocusButton />}
      {!searchFocused && (
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" />
        </div>
      )}
      {searchValue && <ClearSearchButton onClick={clearSearch} />}
      <input
        type="text"
        ref={inputRef}
        className="text-md block w-full rounded-full border border-gray-300 bg-gray-50 py-2.5 pl-10 pr-2.5
               text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-transparent dark:bg-gray-700
               dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
        placeholder="Search registrations..."
        value={searchValue}
        onChange={onSearchChange}
        onFocus={() => setSearchFocused(true)}
        onBlur={() => setSearchFocused(false)}
        onKeyUp={onKeyUp}
      />
    </div>
  );
}

function LoseFocusButton() {
  return (
    <div className="absolute inset-y-0 left-0 flex items-center pl-1">
      <button type="button" className="p-1">
        <ArrowSmallLeftIcon className="min-w-[2rem] text-gray-800 dark:text-gray-300" />
      </button>
    </div>
  );
}

function ClearSearchButton({onClick}: {onClick: () => void}) {
  return (
    <div className="absolute inset-y-0 right-0 flex items-center pr-1">
      <button
        type="button"
        className="rounded-full p-2 transition-all active:bg-gray-200 dark:active:bg-gray-500"
        onClick={onClick}
      >
        <XMarkIcon className="min-w-[1.5rem] text-gray-800 dark:text-gray-300" />
      </button>
    </div>
  );
}

export function TableSkeleton({
  searchData,
  setSearchData,
  registrationCount,
}: {
  registrationCount: number;
  searchData: SearchData;
  setSearchData: (data: SearchData) => void;
}) {
  const rowsPerScreen = Math.ceil(window.innerHeight / ROW_HEIGHT_PX);
  const minRows = 2 * rowsPerScreen;
  const height = (registrationCount > 0 ? registrationCount : minRows) * ROW_HEIGHT_PX;

  return (
    <div>
      <TableFilters searchData={searchData} setSearchData={setSearchData} resultCount={0} />
      <div className="mx-4 mt-2">
        <table className="w-full overflow-hidden rounded-xl text-left text-sm text-gray-500 dark:text-gray-400">
          <tbody>
            <tr className={`bg-gray-200 dark:bg-gray-800 ${styles.animated}`} style={{height}}>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
