import {ReactNode, createContext, useCallback, useState} from 'react';

export interface ErrorModalData {
  type: 'error';
  title: string;
  content: string;
  closeBtnText: string;
  actionBtnText?: string;
  onAction?: () => void;
}

interface ErrorModalParams {
  title: string;
  content?: string;
  closeBtnText?: string;
  actionBtnText?: string;
  onAction?: () => void;
}

export type ErrorModalFunction = (data: ErrorModalParams) => void;

export interface SuccessModalData {
  type: 'success';
  title: string;
  content: string;
  closeBtnText: string;
  actionBtnText: string;
  onAction: () => void;
}

interface SuccessModalParams {
  title: string;
  content?: string;
  closeBtnText?: string;
  actionBtnText?: string;
  onAction: () => void;
}

export type SuccessModalFunction = (data: SuccessModalParams) => void;

export interface ConfirmModalData {
  type: 'confirm';
  title: string;
  content: string;
  closeBtnText: string;
  confirmBtnText: string;
  onConfirm: () => void;
}

interface ConfirmModalParams {
  title: string;
  content?: string;
  closeBtnText?: string;
  confirmBtnText?: string;
  onConfirm: () => void;
}

type ModalData = ErrorModalData | ConfirmModalData | SuccessModalData;

interface ModalContextProps {
  isOpen: boolean;
  data: ModalData;
  closeModal: () => void;
}

const defaultModalData: ErrorModalData = {
  type: 'error',
  title: '',
  content: '',
  closeBtnText: 'Close',
};

export const ModalDataContext = createContext<ModalContextProps>({
  isOpen: false,
  data: defaultModalData,
  closeModal: () => {},
});

export const ErrorModalContext = createContext((data: ErrorModalParams) => {});
export const SuccessModalContext = createContext((data: SuccessModalParams) => {});
export const ConfirmModalContext = createContext((data: ConfirmModalParams) => {});

export const ModalContextProvider = ({children}: {children: ReactNode}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<ModalData>(defaultModalData);

  const errorModal = useCallback(
    ({
      title,
      content = '',
      closeBtnText = 'Close',
      actionBtnText = 'Action',
      onAction,
    }: ErrorModalParams) => {
      setData({
        type: 'error',
        title,
        content,
        closeBtnText,
        actionBtnText,
        onAction,
      });
      setIsOpen(true);
    },
    [setData, setIsOpen]
  );

  const confirmModal = useCallback(
    ({
      title,
      content = '',
      closeBtnText = 'Cancel',
      confirmBtnText = 'Confirm',
      onConfirm,
    }: ConfirmModalParams) => {
      setData({
        type: 'confirm',
        title,
        content,
        closeBtnText,
        confirmBtnText,
        onConfirm,
      });
      setIsOpen(true);
    },
    [setData, setIsOpen]
  );

  const successModal = useCallback(
    ({
      title,
      content = '',
      closeBtnText = 'Close',
      actionBtnText = 'Action',
      onAction,
    }: SuccessModalParams) => {
      setData({
        type: 'success',
        title,
        content,
        closeBtnText,
        actionBtnText,
        onAction,
      });
      setIsOpen(true);
    },
    [setData, setIsOpen]
  );

  const closeModal = () => setIsOpen(false);

  return (
    <ModalDataContext.Provider value={{isOpen, data, closeModal}}>
      <ErrorModalContext.Provider value={errorModal}>
        <ConfirmModalContext.Provider value={confirmModal}>
          <SuccessModalContext.Provider value={successModal}>
            {children}
          </SuccessModalContext.Provider>
        </ConfirmModalContext.Provider>
      </ErrorModalContext.Provider>
    </ModalDataContext.Provider>
  );
};
