import {ChangeEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useLoaderData, useLocation, useNavigate} from 'react-router-dom';
import {
  CalendarDaysIcon,
  ChevronLeftIcon,
  ChevronDownIcon,
  UserIcon,
  BanknotesIcon,
} from '@heroicons/react/20/solid';
import GrowingTextArea from '../../Components/GrowingTextArea';
import IconFeather from '../../Components/Icons/Feather';
import {Typography} from '../../Components/Tailwind';
import IndicoLink from '../../Components/Tailwind/IndicoLink';
import {LoadingIndicator} from '../../Components/Tailwind/LoadingIndicator';
import Title from '../../Components/Tailwind/PageTitle';
import {CheckinToggle} from '../../Components/Tailwind/Toggle';
import TopNav from '../../Components/TopNav';
import db, {
  Event,
  Regform,
  Registration,
  useLiveEvent,
  useLiveRegform,
  useLiveRegistration,
  getEvent,
  getRegform,
  getRegistration,
} from '../../db/db';
import {useHandleError} from '../../hooks/useError';
import {useErrorModal} from '../../hooks/useModal';
import useSettings from '../../hooks/useSettings';
import {useIsOffline} from '../../utils/client';
import {formatDatetime} from '../../utils/date';
import {makeDebounce} from '../../utils/debounce';
import {playVibration} from '../../utils/haptics';
import {playErrorSound} from '../../utils/sound';
import {checkInRegistration} from '../Events/checkin';
import {syncEvent, syncRegistration, syncRegform} from '../Events/sync';
import {NotFoundBanner} from '../NotFound';
import AccompanyingPersons, {MainRegistration} from './AccompanyingPersons';
import {BadgeArea} from './badge';
import {DataAreaClothingSize} from './data';
import {Field, Section, getAccompanyingPersons} from './fields';
import {PaymentWarning, markAsUnpaid} from './payment';
import {RegistrationState} from './RegistrationState';
import {TagsArea} from './tags';

const debounce = makeDebounce(300);

interface Params {
  eventId: number;
  regformId: number;
  registrationId: number;
}

export default function RegistrationPage() {
  const data = useLoaderData() as {
    event?: Event;
    regform?: Regform;
    registration?: Registration;
    params: Params;
  };

  const {eventId, regformId, registrationId} = data.params;
  const event = useLiveEvent(eventId, data.event);
  const regform = useLiveRegform({id: regformId, eventId}, data.regform);
  const registration = useLiveRegistration({id: registrationId, regformId}, data.registration);

  return (
    <>
      <RegistrationTopNav event={event} regform={regform} registration={registration} />
      <RegistrationPageContent
        eventId={eventId}
        regformId={regformId}
        registrationId={registrationId}
        event={event}
        regform={regform}
        registration={registration}
      />
    </>
  );
}

function RegistrationPageContent({
  eventId,
  regformId,
  registrationId,
  event,
  regform,
  registration,
}: {
  eventId: number;
  regformId: number;
  registrationId: number;
  event?: Event;
  regform?: Regform;
  registration?: Registration;
}) {
  const navigate = useNavigate();
  const {state} = useLocation();
  const [autoCheckin, setAutoCheckin] = useState(state?.autoCheckin ?? false);
  const {soundEffect, hapticFeedback} = useSettings();
  const offline = useIsOffline();
  const errorModal = useErrorModal();
  const [notes, setNotes] = useState(registration?.notes || '');
  const showCheckedInWarning = useRef<boolean>(!!state?.fromScan && !!registration?.checkedIn);
  const handleError = useHandleError();

  useEffect(() => {
    // remove autoCheckin and fromScan from location state
    if (state?.autoCheckin !== undefined || state?.fromScan !== undefined) {
      const {autoCheckin, fromScan, ...rest} = state || {};
      // handle auto checkin, if scanner is used
      setAutoCheckin(autoCheckin);
      navigate('.', {replace: true, state: rest});
    }
  }, [navigate, state, registrationId, setAutoCheckin]);

  useEffect(() => {
    if (showCheckedInWarning.current) {
      showCheckedInWarning.current = false;
      if (registration?.checkedIn && registration?.checkedInDt) {
        playErrorSound();
        if (hapticFeedback) {
          playVibration.error();
        }
        errorModal({
          title: 'Registration already checked in',
          content: `This registration was checked in on ${formatDatetime(registration.checkedInDt)}`,
        });
      }
    }
  }, [registration, errorModal, hapticFeedback]);

  const accompanyingPersons = useMemo(() => {
    if (registration?.registrationData) {
      return getAccompanyingPersons(registration.registrationData);
    }
    return [];
  }, [registration]);

  const performCheckin = useCallback(
    async (
      event: Event,
      regform: Regform,
      registration: Registration,
      newCheckinState: boolean
    ) => {
      if (offline) {
        errorModal({title: 'You are offline', content: 'Check-in requires an internet connection'});
        return;
      }

      try {
        await checkInRegistration(
          event,
          regform,
          registration,
          newCheckinState,
          soundEffect,
          hapticFeedback,
          handleError
        );
      } catch (err: any) {
        handleError(err, 'Could not update check-in status');
      } finally {
      }
    },
    [offline, errorModal, handleError, soundEffect, hapticFeedback]
  );

  useEffect(() => {
    const controller = new AbortController();

    async function sync() {
      const event = await getEvent(eventId);
      const regform = await getRegform({id: regformId, eventId});
      let registration = await getRegistration({id: registrationId, regformId});
      if (!event || !regform || !registration) {
        return;
      }

      // sync before checkin
      await syncEvent(event, controller.signal, handleError);
      await syncRegform(event, regform, controller.signal, handleError);
      await syncRegistration(event, regform, registration, controller.signal, handleError);
      registration = await getRegistration({id: registrationId, regformId});
      if (!registration) {
        return;
      }

      if (autoCheckin && !registration.checkedIn) {
        setAutoCheckin(false);
        await performCheckin(event, regform, registration, true);
      }
    }

    sync().catch(err => {
      handleError(err, 'Something went wrong when fetching updates');
    });

    return () => controller.abort();
  }, [eventId, regformId, registrationId, handleError, autoCheckin, offline, performCheckin]);

  if (!event) {
    return <NotFoundBanner text="Event not found" icon={<CalendarDaysIcon />} />;
  } else if (!regform) {
    return <NotFoundBanner text="Registration form not found" icon={<IconFeather />} />;
  } else if (!registration) {
    return <NotFoundBanner text="Registration not found" icon={<UserIcon />} />;
  }

  const onAddNotes = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(e.target.value);
    debounce(() => {
      db.registrations.update(registration.id, {notes: e.target.value});
    });
  };

  const onCheckInToggle = async () => {
    if (!event || !regform || !registration) {
      return;
    }

    if (offline) {
      errorModal({title: 'You are offline', content: 'Check-in requires an internet connection'});
      return;
    }

    await performCheckin(event, regform, registration, !registration.checkedIn);
  };

  let registrationData;
  if (!registration?.registrationData) {
    registrationData = null;
  } else {
    const length = registration.registrationData.length;
    registrationData = registration.registrationData.map((data: Section, i: number) => {
      const section: SectionProps = {
        ...data,
        isFirst: i === 0,
        isLast: i === length - 1,
        isUnique: length === 1,
      };

      return <RegistrationSection key={section.id} {...section} />;
    });
  }

  return (
    <>
      <div className="mx-auto px-4" style={{maxWidth: 1600}}>
        <div className="mt-2 flex flex-col gap-4">
          <div className="flex flex-col items-center gap-2 px-4">
            {registration.personalDataPicture ? (
              <img
                src={registration.personalDataPicture}
                alt="Personal data"
                className="h-32 w-32 rounded-full shadow-lg"
              />
            ) : (
              <UserIcon className="w-16 text-blue-600 dark:text-blue-700" />
            )}
            <Title title={registration.fullName} />
            <IndicoLink
              text="Registration page"
              url={`${event.baseUrl}/event/${event.indicoId}/manage/registration/${regform.indicoId}/registrations/${registration.indicoId}`}
            />
            <div className="flex items-center gap-2">
              <RegistrationState state={registration.state} />
              {registration.price > 0 && (
                <span
                  className="w-fit rounded-full bg-purple-100 px-2.5 py-1 text-sm font-medium
                             text-purple-800 dark:bg-purple-900 dark:text-purple-300"
                >
                  {registration.formattedPrice}
                </span>
              )}
            </div>
          </div>

          <div className="mb-4 mt-4 flex justify-center">
            <CheckinToggle
              checked={registration.checkedIn}
              isLoading={!!registration.checkedInLoading}
              onClick={onCheckInToggle}
            />
          </div>
          <TagsArea
            event={event}
            regform={regform}
            registration={registration}
            handleError={handleError}
          />
          {registration.state === 'unpaid' && (
            <PaymentWarning
              event={event}
              regform={regform}
              registration={registration}
              handleError={handleError}
            />
          )}
          {(registration.state === 'complete' || registration.state === 'unpaid') && (
            <BadgeArea
              event={event}
              regform={regform}
              registration={registration}
              handleError={handleError}
            />
          )}
          {registration.registrationData && <DataAreaClothingSize registration={registration} />}
          {accompanyingPersons.length > 0 &&
            (registration.indicoId.includes('-') ? (
              <MainRegistration data={registration.registrationData} />
            ) : (
              <AccompanyingPersons persons={accompanyingPersons} />
            ))}
          <Typography as="div" variant="body1" className="mt-1 flex w-full justify-center">
            <GrowingTextArea value={notes} onChange={onAddNotes} />
          </Typography>
        </div>
      </div>
      <div className="mx-auto mt-5 flex flex-col px-4" style={{maxWidth: 1600}}>
        {registrationData || <LoadingIndicator />}
      </div>
    </>
  );
}

function RegistrationTopNav({
  event,
  regform,
  registration,
}: {
  event?: Event;
  regform?: Regform;
  registration?: Registration;
}) {
  const {state} = useLocation();
  const handleError = useHandleError();

  if (!event || !regform || !registration) {
    return <TopNav />;
  }
  const backNavigateTo = `/event/${event.id}`;

  if (registration.price === 0 || !registration.isPaid) {
    return <TopNav backBtnText={regform.title} backNavigateTo={backNavigateTo} />;
  }

  return (
    <TopNav
      backBtnText={regform.title}
      backNavigateTo={backNavigateTo}
      settingsItems={[
        {
          text: 'Mark as unpaid',
          icon: <BanknotesIcon className="text-green-500" />,
          onClick: async () => {
            if (!event || !regform || !registration) {
              return;
            }

            await markAsUnpaid(event, regform, registration, handleError);
          },
        },
      ]}
    />
  );
}

interface SectionProps extends Section {
  isFirst: boolean;
  isLast: boolean;
  isUnique: boolean;
}

function RegistrationSection(section: SectionProps) {
  const {title, fields, isFirst, isLast, isUnique} = section;
  const [isOpen, setIsOpen] = useState(false);

  let border = '';
  if (isFirst) {
    border += ' rounded-t-xl';
    if (!isUnique && !isOpen) {
      border += ' border-b-0';
    }
  }
  if (isLast && !isOpen) {
    border += ' rounded-b-xl';
  }
  if (isUnique && !isOpen) {
    border += ' rounded-b-xl';
  }

  let bgColor = '';
  if (isOpen) {
    bgColor += ' bg-blue-100 dark:bg-gray-700';
  } else {
    bgColor += ' bg-gray-100 dark:bg-gray-800';
  }

  let expandedBorder = '';
  if (isUnique || isLast) {
    expandedBorder += ' border-b rounded-b-xl';
  }

  return (
    <div>
      <div>
        <button
          type="button"
          disabled={fields.length === 0}
          className={`flex w-full items-center justify-between border border-gray-200 p-5 text-left
                      font-medium transition-all dark:border-gray-700 ${bgColor} ${border}`}
          onClick={() => setIsOpen(o => !o)}
        >
          <Typography variant="h4" className="flex w-full justify-between">
            {title}
            {isOpen && <ChevronDownIcon className="h-6 w-6 min-w-6" />}
            {!isOpen && <ChevronLeftIcon className="h-6 w-6 min-w-6" />}
          </Typography>
        </button>
      </div>
      <div className={isOpen ? '' : 'hidden'}>
        <div
          className={`flex flex-col gap-2 border-l border-r px-5 py-5 dark:border-gray-700 ${expandedBorder}`}
        >
          {fields.map(field => (
            <Field key={field.id} {...field} />
          ))}
        </div>
      </div>
    </div>
  );
}
