import Dexie, {type EntityTable} from 'dexie';
import {useLiveQuery} from 'dexie-react-hooks';
import {FieldProps} from '../pages/registration/fields';
import {IndicoEvent, IndicoRegistration, IndicoRegform, IndicoParticipant} from '../utils/client';
import {deepEqual} from '../utils/deep_equal';

export type IDBBoolean = 1 | 0; // IndexedDB doesn't support indexing booleans, so we use {1,0} instead

interface _Server {
  baseUrl: string;
  clientId: string;
  scope: string;
  authToken: string;
}
export interface Server extends _Server {
  id: number;
}

type AddServer = _Server;
type GetServer = number | {baseUrl: string};

type _DesignerTemplateOrientation = 'portrait' | 'landscape';
type _DesignerTemplateFormat = 'custom' | string;
export interface DesignerTemplate {
  id: number;
  title: string;
  orientation: _DesignerTemplateOrientation;
  format: _DesignerTemplateFormat;
}

export interface EventAccessZone {
  uuid: string;
  label: string;
  badgeCategory?: string;
  badgeLabel: string;
  internal: boolean;
}

export interface EventTag {
  id: number;
  label: string;
  badgeLabel: string;
  internal: boolean;
  hideParticipantsInLists: boolean;
}

export interface EventRoom {
  uuid: string;
  label: string;
}

export interface EventRegform {
  id: number;
  title: string;
}

export interface _Event {
  indicoId: number;
  serverId: number;
  baseUrl: string;
  title: string;
  date: string;
  templates: DesignerTemplate[];
  accessZones: EventAccessZone[];
  participantTags: EventTag[];
  rooms: EventRoom[];
  regforms: EventRegform[];
  occupancy?: {in: number; out: number};
}

export interface Event extends _Event {
  id: number;
  participantCount: number;
  checkedInCount: number;
  deleted: IDBBoolean;
}

interface AddEvent extends _Event {
  participantCount?: number;
  checkedInCount?: number;
  deleted?: boolean;
}

type GetEvent = number;

export interface _Regform {
  indicoId: number;
  eventId: number;
  title: string;
}

export interface Regform extends _Regform {
  id: number;
  isOpen: boolean;
  registrationCount: number;
  checkedInCount: number;
  deleted: IDBBoolean;
}

interface AddRegform extends _Regform {
  isOpen?: boolean;
  registrationCount?: number;
  checkedInCount?: number;
  deleted?: boolean;
}

type GetRegform =
  | number
  | {
      id: number;
      eventId: number;
    };

export interface RegistrationData {
  id: number;
  title: string;
  description: string;
  fields: FieldProps[];
}

export type RegistrationState = 'complete' | 'pending' | 'rejected' | 'withdrawn' | 'unpaid';

interface _Registration {
  indicoId: string;
  hashId: string;
  regformId: number;
  fullName: string;
  company: string;
  token: string;
  registrationDate: string;
  // registrationData is not sent by Indico when listing all registrations to save on bandwith.
  // We only fetch it once we actually navigate to the registration details page.
  registrationData?: RegistrationData[];
  tags: string[];
  state: RegistrationState;
  checkinSecret: string;
  checkedIn: boolean;
  checkedInDt?: string;
  occupiedSlots: number;
  price: number;
  currency: string;
  formattedPrice: string;
  isPaid: boolean;
}

export interface Registration extends _Registration {
  id: number;
  checkedInLoading: IDBBoolean; // 1 (true) while the request to check in is in progress
  deleted: IDBBoolean;
  notes: string;
  isPaidLoading: IDBBoolean; // 1 (true) while the request to mark as (un)paid is in progress
  personalDataPicture?: string;
}

interface AddRegistration extends _Registration {
  deleted?: IDBBoolean;
  notes?: string;
}

export interface ParticipantRegistration {
  id: number;
  regformId: number;
  eventId: number;
  token: string;
  checkinSecret: string;
  regformTitle: string;
  registrationDate: string;
  state: string;
}

export interface ParticipantAccessZone {
  access: boolean;
  entered?: string;
}

export interface ParticipantToAccessZone {
  [accessZoneUuid: string]: ParticipantAccessZone;
}

export interface ParticipantToTag {
  [participantTagId: number]: boolean;
}

interface _Participant {
  uuid: string;
  email: string;
  salutation?: string;
  title?: string;
  firstName?: string;
  lastName?: string;
  position?: string;
  company?: string;
  tags?: ParticipantToTag;
  accessZones?: ParticipantToAccessZone;
  registrations?: ParticipantRegistration[];
  registrationDate?: string;
  checkedIn: boolean;
  checkedInDt?: string;
}

export interface Participant extends _Participant {
  id: number;
  checkedInLoading: IDBBoolean; // 1 (true) while the request to check in is in progress
  deleted: IDBBoolean;
}

interface AddParticipant extends _Participant {
  eventId: number;
  deleted?: IDBBoolean;
}

type GetRegistration =
  | number
  | {
      id: number;
      regformId: number;
    };

type GetParticipant =
  | number
  | {
      id: number;
      eventId: number;
    };

class IndicoCheckin extends Dexie {
  // Declare implicit table properties.
  // (just to inform Typescript. Instanciated by Dexie in stores() method)
  servers!: EntityTable<Server, 'id'>;
  events!: EntityTable<Event, 'id'>;
  regforms!: EntityTable<Regform, 'id'>;
  registrations!: EntityTable<Registration, 'id'>;
  participants!: EntityTable<Participant, 'id'>;

  constructor() {
    super('CheckinDatabase');
    this.version(2).stores({
      servers: 'id++, baseUrl, clientId',
      events: 'id++, indicoId, serverId, deleted, [indicoId+serverId]',
      regforms:
        'id++, indicoId, eventId, deleted, [id+eventId], [indicoId+eventId], [eventId+deleted]',
      registrations:
        'id++, indicoId, hashId, company, token, regformId, deleted, checkinSecret, checkedInLoading, isPaidLoading, tags, [id+regformId], [indicoId+regformId], [regformId+deleted]',
      participants:
        'id++, eventId, uuid, email, salutation, title, firstName, lastName, position, company, tags, accessZones, registrations, checkinSecrets, checkedInLoading, [id+eventId], [uuid+eventId], [eventId+deleted]',
    });
  }
}

const db = new IndicoCheckin();

export default db;

export async function getServer(id: GetServer) {
  if (typeof id === 'number') {
    return await db.servers.get(id);
  }
  return await db.servers.get(id);
}

export async function getEvent(id: GetEvent) {
  return await db.events.get(id);
}

export async function getRegform(id: GetRegform) {
  if (typeof id === 'number') {
    return await db.regforms.get(id);
  }
  return await db.regforms.get(id);
}

export async function getRegistration(id: GetRegistration) {
  if (typeof id === 'number') {
    return await db.registrations.get(id);
  }
  return await db.registrations.get(id);
}

export async function getParticipant(id: GetParticipant) {
  if (typeof id === 'number') {
    return await db.participants.get(id);
  }
  return await db.participants.get(id);
}

export async function getRegistrationByUuid(uuid: string) {
  return await db.registrations.where({checkinSecret: uuid}).first();
}

export async function getServers() {
  return await db.servers.toArray();
}

export async function getEvents() {
  return await db.events.where({deleted: 0}).toArray();
}

export async function getEventByServerAndIndicoId(serverId: number, indicoEventId: number) {
  return await db.events.where({deleted: 0, serverId: serverId, indicoId: indicoEventId}).first();
}

export async function getRegforms(eventId?: number) {
  if (eventId === undefined) {
    return await db.regforms.where({deleted: 0}).toArray();
  }
  return await db.regforms.where({eventId, deleted: 0}).toArray();
}

export async function getRegistrations(regformId: number) {
  return await db.registrations.where({regformId, deleted: 0}).toArray();
}

export async function getEventRegistrations(eventId?: number): Promise<Registration[]> {
  const _regforms = await getRegforms(eventId);

  if (_regforms.length > 0) {
    const _registrations = await Promise.all(
      _regforms.map(async (_regform, currVal) => {
        const _registrations = await db.registrations
          .where({regformId: _regform.id, deleted: 0})
          .toArray();
        return _registrations;
      }, [])
    );
    return _registrations.reduce(
      (_registrationsOfRegform, currVal) => currVal.concat(_registrationsOfRegform),
      []
    );
  }
  return [];
}

export async function getEventParticipants(eventId?: number): Promise<Participant[]> {
  return await db.participants.where({eventId, deleted: 0}).toArray();
}

export async function countRegistrations(regformId: number) {
  return await db.registrations.where({regformId, deleted: 0}).count();
}

export async function countEventRegistrations(eventId: number) {
  const _regforms = await getRegforms(eventId);

  if (_regforms.length > 0) {
    const _registrationCount = await Promise.all(
      _regforms.map(async (_regform, currVal) => {
        const _count = await db.registrations.where({regformId: _regform.id, deleted: 0}).count();
        return _count;
      }, [])
    );
    return _registrationCount.reduce((_count, currVal) => _count + currVal, 0);
  }
  return 0;
}

export function useLiveServers(defaultValue?: Server[]) {
  return useLiveQuery(getServers, [], defaultValue || []);
}

export function useLiveEvent(eventId: number, defaultValue?: Event) {
  return useLiveQuery(() => getEvent(eventId), [eventId], defaultValue);
}

export function useLiveIndicoEvent(serverId: number, indicoEventId: number, defaultValue?: Event) {
  return useLiveQuery(
    () => getEventByServerAndIndicoId(serverId, indicoEventId),
    [serverId, indicoEventId],
    defaultValue
  );
}

export function useLiveEvents(defaultValue?: Event[]) {
  return useLiveQuery(getEvents, [], defaultValue || []);
}

export function useLiveRegform(id: GetRegform, defaultValue?: Regform) {
  const deps = typeof id === 'number' ? [id] : Object.values(id);
  return useLiveQuery(() => getRegform(id), deps, defaultValue);
}

export function useLiveRegforms(eventId?: number, defaultValue?: Regform[]) {
  const deps = eventId === undefined ? [] : [eventId];
  return useLiveQuery(() => getRegforms(eventId), deps, defaultValue || []);
}

export function useLiveRegistration(id: GetRegistration, defaultValue?: Registration) {
  const deps = typeof id === 'number' ? [id] : Object.values(id);
  return useLiveQuery(() => getRegistration(id), deps, defaultValue);
}

export function useLiveParticipant(id: GetParticipant, defaultValue?: Participant) {
  const deps = typeof id === 'number' ? [id] : Object.values(id);
  return useLiveQuery(() => getParticipant(id), deps, defaultValue);
}

export function useLiveRegistrations(regformId: number) {
  return useLiveQuery(() => getRegistrations(regformId), [regformId]);
}

export function useLiveEventRegistrations(eventId?: number, defaultValue?: Registration[]) {
  const deps = eventId === undefined ? [] : [eventId];
  return useLiveQuery(() => getEventRegistrations(eventId), deps, defaultValue || []);
}

export function useLiveEventParticipants(eventId?: number, defaultValue?: Participant[]) {
  const deps = eventId === undefined ? [] : [eventId];
  return useLiveQuery(() => getEventParticipants(eventId), deps, defaultValue || []);
}

export async function addServer(data: AddServer): Promise<number> {
  return await db.servers.add(data);
}

export async function addEvent(event: AddEvent): Promise<number> {
  const deleted = event.deleted ? 1 : 0;
  const participantCount = event.participantCount || 0;
  const checkedInCount = event.checkedInCount || 0;
  return await db.events.add({...event, participantCount, checkedInCount, deleted});
}

export async function addEventIfNotExists(data: AddEvent): Promise<number> {
  const {indicoId, serverId} = data;
  let id!: number;

  await db.transaction('readwrite', db.events, async () => {
    const event = await db.events.get({indicoId, serverId});

    if (event) {
      id = event.id;
    } else {
      id = await addEvent(data);
    }
  });
  return id;
}

export async function addRegform(regform: AddRegform): Promise<number> {
  const isOpen = !!regform.isOpen;
  const registrationCount = regform.registrationCount || 0;
  const checkedInCount = regform.checkedInCount || 0;
  const deleted = (regform.deleted ? 1 : 0) as IDBBoolean;
  const data = {
    ...regform,
    isOpen,
    registrationCount,
    checkedInCount,
    deleted,
  };

  // See comment in addRegistrations()
  if (isFirefox()) {
    let id!: number;
    await db.transaction('readwrite', db.regforms, async () => {
      id = await db.regforms.add(data);
      await db.regforms.put({id, ...data});
    });
    return id;
  } else {
    return await db.regforms.add(data);
  }
}

export async function addRegformIfNotExists(data: AddRegform): Promise<number> {
  const {indicoId, eventId} = data;
  let id!: number;

  await db.transaction('readwrite', db.regforms, async () => {
    const regform = await db.regforms.get({indicoId, eventId});

    if (regform) {
      id = regform.id;
    } else {
      id = await addRegform(data);
    }
  });
  return id;
}

export async function addRegistration(registration: AddRegistration): Promise<number> {
  const data = {
    ...registration,
    deleted: (registration.deleted ? 1 : 0) as IDBBoolean,
    checkedInLoading: 0 as IDBBoolean,
    notes: registration.notes || '',
    isPaidLoading: 0 as IDBBoolean,
  };

  // See comment in addRegistrations()
  if (isFirefox()) {
    let id!: number;
    await db.transaction('readwrite', db.registrations, async () => {
      id = await db.registrations.add(data);
      await db.registrations.put({id, ...data});
    });
    return id;
  } else {
    return await db.registrations.add(data);
  }
}

export async function addParticipant(participant: AddParticipant): Promise<number> {
  const data = {
    ...participant,
    deleted: (participant.deleted ? 1 : 0) as IDBBoolean,
    checkedInLoading: 0 as IDBBoolean,
  };

  // See comment in addRegistrations()
  if (isFirefox()) {
    let id!: number;
    await db.transaction('readwrite', db.participants, async () => {
      id = await db.participants.add(data);
      await db.participants.put({id, ...data});
    });
    return id;
  } else {
    return await db.participants.add(data);
  }
}

export async function addRegistrationIfNotExists(data: AddRegistration): Promise<number> {
  const {indicoId, regformId} = data;
  let id!: number;

  await db.transaction('readwrite', db.registrations, async () => {
    const registration = await db.registrations.get({indicoId, regformId});
    if (registration) {
      id = registration.id;
    } else {
      id = await addRegistration(data);
    }
  });
  return id;
}

export async function addRegistrations(data: AddRegistration[]) {
  const registrations = data.map(p => ({
    ...p,
    checkedInLoading: 0 as IDBBoolean,
    deleted: (p.deleted ? 1 : 0) as IDBBoolean,
    notes: p.notes || '',
    isPaidLoading: 0 as IDBBoolean,
  }));

  // Firefox does not support compound indexes with auto-incrementing keys
  // Essentially, when inserting an item with an auto-incrementing key, the
  // index does not get updated so the item is not found when searching for it afterwards
  // A workaround is to first insert the items and then use put() with the new key which
  // forces the index to update.
  // https://bugzilla.mozilla.org/show_bug.cgi?id=1404276
  // There was a similar issue which has been fixed:
  // https://bugs.chromium.org/p/chromium/issues/detail?id=701972
  if (isFirefox()) {
    return await db.transaction('readwrite', db.registrations, async () => {
      const ids = await db.registrations.bulkAdd(registrations, {allKeys: true});
      const registrationsWithIds = registrations.map((p, i) => ({...p, id: ids[i]}));
      await db.registrations.bulkPut(registrationsWithIds);
    });
  } else {
    return await db.registrations.bulkAdd(registrations);
  }
}

export async function addParticipants(data: AddParticipant[]) {
  const participants = data.map(p => ({
    ...p,
    checkedInLoading: 0 as IDBBoolean,
    deleted: (p.deleted ? 1 : 0) as IDBBoolean,
  }));

  // Firefox does not support compound indexes with auto-incrementing keys
  // Essentially, when inserting an item with an auto-incrementing key, the
  // index does not get updated so the item is not found when searching for it afterwards
  // A workaround is to first insert the items and then use put() with the new key which
  // forces the index to update.
  // https://bugzilla.mozilla.org/show_bug.cgi?id=1404276
  // There was a similar issue which has been fixed:
  // https://bugs.chromium.org/p/chromium/issues/detail?id=701972
  if (isFirefox()) {
    return await db.transaction('readwrite', db.participants, async () => {
      const ids = await db.participants.bulkAdd(participants, {allKeys: true});
      const participantsWithIds = participants.map((p, i) => ({...p, id: ids[i]}));
      await db.participants.bulkPut(participantsWithIds);
    });
  } else {
    return await db.participants.bulkAdd(participants);
  }
}

export async function deleteEvent(id: number) {
  return db.transaction('readwrite', [db.events, db.regforms, db.registrations], async () => {
    const regforms = await db.regforms.where({eventId: id}).toArray();
    for (const regform of regforms) {
      await db.registrations.where({regformId: regform.id}).delete();
      await db.regforms.delete(regform.id);
    }
    await db.events.delete(id);
  });
}

export async function updateEvent(id: number, data: IndicoEvent) {
  const {id: indicoId, title, startDt: date, ...rest} = data; // TODO: startDt, endDt
  await db.events.update(id, {indicoId, title, date, ...rest});
}

export async function deleteRegform(id: number) {
  return db.transaction('readwrite', [db.regforms, db.registrations], async () => {
    await db.registrations.where({regformId: id}).delete();
    await db.regforms.delete(id);
  });
}

export async function updateRegform(id: number, data: IndicoRegform) {
  const {id: indicoId, eventId, introduction, startDt, endDt, ...rest} = data;
  await db.regforms.update(id, {indicoId, ...rest});
}

export async function updateRegforms(ids: number[], regforms: IndicoRegform[]) {
  const updates = regforms.map(
    ({id: indicoId, eventId, introduction, startDt, endDt, ...rest}, i) => ({
      key: ids[i],
      changes: {indicoId, ...rest},
    })
  );
  await db.regforms.bulkUpdate(updates);
}

export async function updateRegistration(id: number, data: IndicoRegistration) {
  const {id: indicoId, eventId, regformId, ...rest} = data;
  await db.registrations.update(id, {indicoId, ...rest});
}

export async function updateParticipant(id: number, data: IndicoParticipant) {
  await db.participants.update(id, data);
}

export async function updateRegistrations(ids: number[], registrations: IndicoRegistration[]) {
  const newRegistrations = registrations.map(({id: indicoId, eventId, regformId, ...rest}) => ({
    indicoId,
    ...rest,
  }));

  return db.transaction('readwrite', db.registrations, async () => {
    const storedRegistrations = await db.registrations.bulkGet(ids);
    const changes = [];
    for (const [i, p] of storedRegistrations.entries()) {
      if (!p) {
        continue;
      }
      const keysToCompare = new Set(Object.keys(newRegistrations[i]));
      const pChanges = Object.fromEntries(
        Object.entries(p).filter(([key]) => keysToCompare.has(key))
      );
      if (!deepEqual(pChanges, newRegistrations[i])) {
        changes.push({key: ids[i], changes: newRegistrations[i]});
      }
    }
    await db.registrations.bulkUpdate(changes);
  });
}

export async function updateParticipants(ids: number[], participants: IndicoParticipant[]) {
  // const newParticipants = participants.map(({id: indicoId, eventId, ...rest}) => ({
  //   indicoId,
  //   ...rest,
  // }));

  return db.transaction('readwrite', db.participants, async () => {
    const storedParticipants = await db.participants.bulkGet(ids);
    const changes = [];
    for (const [i, p] of storedParticipants.entries()) {
      if (!p) {
        continue;
      }
      const keysToCompare = new Set(Object.keys(participants[i]));
      const pChanges = Object.fromEntries(
        Object.entries(p).filter(([key]) => keysToCompare.has(key))
      );
      if (!deepEqual(pChanges, participants[i])) {
        changes.push({key: ids[i], changes: participants[i]});
      }
    }
    await db.participants.bulkUpdate(changes);
  });
}

function isFirefox() {
  return navigator.userAgent.toLowerCase().includes('firefox');
}
