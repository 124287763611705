import {useCallback, useEffect, useRef, useState} from 'react';
import {useLoaderData, useLocation, useNavigate} from 'react-router-dom';
import {
  CalendarDaysIcon,
  ChevronLeftIcon,
  ChevronDownIcon,
  UserIcon,
  BanknotesIcon,
} from '@heroicons/react/20/solid';
import {Typography} from '../../Components/Tailwind';
import IndicoLink from '../../Components/Tailwind/IndicoLink';
import {LoadingIndicator} from '../../Components/Tailwind/LoadingIndicator';
import Title from '../../Components/Tailwind/PageTitle';
import {CheckinToggle} from '../../Components/Tailwind/Toggle';
import TopNav from '../../Components/TopNav';
import db, {
  Event,
  Registration,
  useLiveEvent,
  getEvent,
  Participant,
  useLiveParticipant,
  getParticipant,
} from '../../db/db';
import {useHandleError} from '../../hooks/useError';
import {useErrorModal} from '../../hooks/useModal';
import useSettings from '../../hooks/useSettings';
import {useIsOffline} from '../../utils/client';
import {formatDatetime} from '../../utils/date';
import {makeDebounce} from '../../utils/debounce';
import {playVibration} from '../../utils/haptics';
import {playErrorSound} from '../../utils/sound';
import {checkInParticipant} from '../Events/checkin';
import {syncEvent, syncParticipant} from '../Events/sync';
import {NotFoundBanner} from '../NotFound';
import {ParticipantAccessArea, ParticipantBadgeArea, ParticipantTagsArea} from './ParticipantAreas';

const debounce = makeDebounce(300);

interface Params {
  eventId: number;
  participantId: number;
}

export default function ParticipantPage() {
  const data = useLoaderData() as {
    event?: Event;
    participant?: Participant;
    registration?: Registration;
    params: Params;
  };

  const {eventId, participantId} = data.params;
  const event = useLiveEvent(eventId, data.event);
  const participant = useLiveParticipant({id: participantId, eventId}, data.participant);

  return (
    <>
      <ParticipantTopNav event={event} participant={participant} />
      <ParticantPageContent
        eventId={eventId}
        participantId={participantId}
        event={event}
        participant={participant}
      />
    </>
  );
}

function ParticantPageContent({
  eventId,
  participantId,
  event,
  participant,
}: {
  eventId: number;
  participantId: number;
  event?: Event;
  participant?: Participant;
}) {
  const navigate = useNavigate();
  const {state} = useLocation();
  const {soundEffect, hapticFeedback} = useSettings();
  const offline = useIsOffline();
  const errorModal = useErrorModal();
  const showCheckedInWarning = useRef<boolean>(!!state?.fromScan && !!participant?.checkedIn);
  const handleError = useHandleError();

  useEffect(() => {
    // remove fromScan from location state
    if (state?.fromScan !== undefined) {
      const {fromScan, ...rest} = state || {};
      navigate('.', {replace: true, state: rest});
    }
  }, [navigate, state]);

  useEffect(() => {
    if (showCheckedInWarning.current) {
      showCheckedInWarning.current = false;
      if (participant?.checkedIn && participant?.checkedInDt) {
        playErrorSound();
        if (hapticFeedback) {
          playVibration.error();
        }
        errorModal({
          title: 'Registration already checked in',
          content: `This participant was checked in on ${formatDatetime(participant.checkedInDt)}`,
        });
      }
    }
  }, [participant, errorModal, hapticFeedback]);

  const performCheckin = useCallback(
    async (event: Event, participant: Participant, newCheckinState: boolean) => {
      if (offline) {
        errorModal({title: 'You are offline', content: 'Check-in requires an internet connection'});
        return;
      }

      try {
        await checkInParticipant(
          event,
          participant,
          newCheckinState,
          soundEffect,
          hapticFeedback,
          handleError
        );
      } catch (err: any) {
        handleError(err, 'Could not update check-in status');
      } finally {
      }
    },
    [offline, errorModal, handleError, soundEffect, hapticFeedback]
  );

  useEffect(() => {
    const controller = new AbortController();

    async function sync() {
      const event = await getEvent(eventId);
      const participant = await getParticipant(participantId);
      if (!event || !participant) {
        return;
      }

      // sync before checkin
      await syncEvent(event, controller.signal, handleError);
      await syncParticipant(event, participant, controller.signal, handleError);
    }

    sync().catch(err => {
      handleError(err, 'Something went wrong when fetching updates');
    });

    return () => controller.abort();
  }, [eventId, participantId, handleError, offline, performCheckin]);

  if (!event) {
    return <NotFoundBanner text="Event not found" icon={<CalendarDaysIcon />} />;
  } else if (!participant) {
    return <NotFoundBanner text="Participant not found" icon={<UserIcon />} />;
  }

  const onCheckInToggle = async () => {
    if (!event || !participant) {
      return;
    }

    if (offline) {
      errorModal({title: 'You are offline', content: 'Check-in requires an internet connection'});
      return;
    }

    await performCheckin(event, participant, !participant.checkedIn);
  };

  // let registrationData;
  // if (!registration?.registrationData) {
  //   registrationData = null;
  // } else {
  //   const length = registration.registrationData.length;
  //   registrationData = registration.registrationData.map((data: Section, i: number) => {
  //     const section: SectionProps = {
  //       ...data,
  //       isFirst: i === 0,
  //       isLast: i === length - 1,
  //       isUnique: length === 1,
  //     };

  //     return <RegistrationSection key={section.id} {...section} />;
  //   });
  // }

  return (
    <>
      <div className="mx-auto px-4" style={{maxWidth: 1600}}>
        <div className="mt-2 flex flex-col gap-4">
          <div className="flex flex-col items-center gap-2 px-4">
            <UserIcon className="w-16 text-blue-600 dark:text-blue-700" />

            <Title title={`${participant.firstName} ${participant.lastName}`} />
            <IndicoLink
              text="Participant page"
              url={`${event.baseUrl}/event/${event.indicoId}/manage/badges/participants/${participant.uuid}`}
            />
            {/* <div className="flex items-center gap-2">
              <RegistrationState state={registration.state} />
              {registration.price > 0 && (
                <span
                  className="w-fit rounded-full bg-purple-100 px-2.5 py-1 text-sm font-medium
                             text-purple-800 dark:bg-purple-900 dark:text-purple-300"
                >
                  {registration.formattedPrice}
                </span>
              )}
            </div> */}
          </div>

          <div className="mb-4 mt-4 flex justify-center">
            <CheckinToggle
              checked={participant.checkedIn}
              isLoading={!!participant.checkedInLoading}
              onClick={onCheckInToggle}
            />
          </div>
          <ParticipantAccessArea
            event={event}
            participant={participant}
            handleError={handleError}
          />
          <ParticipantTagsArea event={event} participant={participant} handleError={handleError} />
          <ParticipantBadgeArea event={event} participant={participant} handleError={handleError} />
          {/* <ParticipantRegistrationsArea
            event={event}
            participant={participant}
            handleError={handleError}
          />  */}
        </div>
      </div>
      {/* <div className="mx-auto mt-5 flex flex-col px-4" style={{maxWidth: 1600}}>
        {registrationData || <LoadingIndicator />}
      </div> */}
    </>
  );
}

function ParticipantTopNav({event, participant}: {event?: Event; participant?: Participant}) {
  if (!event || !participant) {
    return <TopNav />;
  }
  const backNavigateTo = `/event/${event.id}/participants`;

  return (
    <TopNav
      backBtnText={event.title}
      backNavigateTo={backNavigateTo}
      // settingsItems={[
      //   {
      //     text: 'Mark as unpaid',
      //     icon: <BanknotesIcon className="text-green-500" />,
      //     onClick: async () => {
      //       if (!event || !regform || !registration) {
      //         return;
      //       }

      //       await markAsUnpaid(event, regform, registration, handleError);
      //     },
      //   },
      // ]}
    />
  );
}

// interface SectionProps extends Section {
//   isFirst: boolean;
//   isLast: boolean;
//   isUnique: boolean;
// }

// function RegistrationSection(section: SectionProps) {
//   const {title, fields, isFirst, isLast, isUnique} = section;
//   const [isOpen, setIsOpen] = useState(false);

//   let border = '';
//   if (isFirst) {
//     border += ' rounded-t-xl';
//     if (!isUnique && !isOpen) {
//       border += ' border-b-0';
//     }
//   }
//   if (isLast && !isOpen) {
//     border += ' rounded-b-xl';
//   }
//   if (isUnique && !isOpen) {
//     border += ' rounded-b-xl';
//   }

//   let bgColor = '';
//   if (isOpen) {
//     bgColor += ' bg-blue-100 dark:bg-gray-700';
//   } else {
//     bgColor += ' bg-gray-100 dark:bg-gray-800';
//   }

//   let expandedBorder = '';
//   if (isUnique || isLast) {
//     expandedBorder += ' border-b rounded-b-xl';
//   }

//   return (
//     <div>
//       <div>
//         <button
//           type="button"
//           disabled={fields.length === 0}
//           className={`flex w-full items-center justify-between border border-gray-200 p-5 text-left
//                       font-medium transition-all dark:border-gray-700 ${bgColor} ${border}`}
//           onClick={() => setIsOpen(o => !o)}
//         >
//           <Typography variant="h4" className="flex w-full justify-between">
//             {title}
//             {isOpen && <ChevronDownIcon className="h-6 w-6 min-w-6" />}
//             {!isOpen && <ChevronLeftIcon className="h-6 w-6 min-w-6" />}
//           </Typography>
//         </button>
//       </div>
//       <div className={isOpen ? '' : 'hidden'}>
//         <div
//           className={`flex flex-col gap-2 border-l border-r px-5 py-5 dark:border-gray-700 ${expandedBorder}`}
//         >
//           {fields.map(field => (
//             <Field key={field.id} {...field} />
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }
