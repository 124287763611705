import {useEffect, useState} from 'react';
import {useLoaderData, useNavigate} from 'react-router-dom';
import {CalendarDaysIcon, UserGroupIcon} from '@heroicons/react/20/solid';
import {CheckCircleIcon, TrashIcon} from '@heroicons/react/24/solid';
import IconFeather from '../../Components/Icons/Feather';
import {Typography} from '../../Components/Tailwind';
import {makeDefaultFilterState} from '../../Components/Tailwind/filters';
import IndicoLink from '../../Components/Tailwind/IndicoLink';
import Title from '../../Components/Tailwind/PageTitle';
import Table, {SearchData, TableSkeleton} from '../../Components/Tailwind/Table';
import TopNav from '../../Components/TopNav';
import {
  Event,
  Registration,
  Regform,
  deleteEvent as _deleteEvent,
  getEvent,
  getRegforms,
  useLiveEvent,
  useLiveEventRegistrations,
} from '../../db/db';
import {useHandleError} from '../../hooks/useError';
import {useConfirmModal, useErrorModal} from '../../hooks/useModal';
import {wait} from '../../utils/wait';
import {syncEvent, syncRegistrations} from '../Events/sync';
import {NotFoundBanner} from '../NotFound';
import {NoRegistrationsBanner} from '../regform/RegformPage';
interface Params {
  eventId: number;
}

export default function EventPage() {
  const loaderData = useLoaderData() as {
    event?: Event;
    registrations: Registration[];
    params: Params;
    registrationCount: number;
  };

  const {eventId} = loaderData.params;
  const event = useLiveEvent(eventId, loaderData.event);
  // Registrations are preloaded in case there is a lot of them (10k+) as this
  // would make this page too slow to load w/o visual feedback. Instead,
  // the page is loaded immediately and a table skeleton is shown while the
  // registrations are loading.
  const registrations = useLiveEventRegistrations(eventId, loaderData.registrations);

  return (
    <>
      <EventTopNav event={event} />
      <EventPageContent
        eventId={eventId}
        event={event}
        registrations={registrations}
        registrationCount={loaderData.registrationCount}
      />
    </>
  );
}

function EventPageContent({
  eventId,
  event,
  registrations,
  registrationCount,
}: {
  eventId: number;
  event?: Event;
  registrations?: Registration[];
  registrationCount: number;
}) {
  const navigate = useNavigate();
  const handleError = useHandleError();
  const [isSyncing, setIsSyncing] = useState(false);
  const savedFilters = JSON.parse(localStorage.getItem('registrationFilters') || '{}')[eventId];
  const [searchData, _setSearchData] = useState({
    searchValue: savedFilters?.searchValue || '',
    filters: savedFilters?.filters || makeDefaultFilterState(),
  });

  const setSearchData = (data: SearchData) => {
    _setSearchData(data);
    const events = JSON.parse(localStorage.getItem('registrationFilters') || '{}');
    events[eventId] = data;
    localStorage.setItem('registrationFilters', JSON.stringify(events));
  };

  useEffect(() => {
    const controller = new AbortController();
    async function _sync() {
      const event = await getEvent(eventId);
      if (!event) {
        return;
      }
      const _regforms = await getRegforms(eventId);

      await syncEvent(event, controller.signal, handleError);
      await Promise.all(
        _regforms
          .filter(_regform => _regform !== undefined)
          .map(
            async _regform =>
              await syncRegistrations(event, _regform as Regform, controller.signal, handleError)
          )
      );
    }

    async function sync() {
      setIsSyncing(true);
      try {
        await _sync();
      } catch (err: any) {
        handleError(err, 'Something went wrong when fetching updates');
      } finally {
        if (!controller.signal.aborted) {
          setIsSyncing(false);
        }
      }
    }

    sync();
    return () => controller.abort();
  }, [eventId, setIsSyncing, handleError]);

  if (!event) {
    return <NotFoundBanner text="Event not found" icon={<CalendarDaysIcon />} />;
  }

  // const navigateToRegform = (idx = 0) => {
  //   navigate(`/event/${eventId}/${regforms[idx].id}`);
  // };
  return (
    <div className="mx-auto px-4" style={{maxWidth: 1600}}>
      <div className="flex flex-col items-center gap-2">
        <CalendarDaysIcon className="w-16 text-blue-600 dark:text-blue-700" />
        <Title title={event.title} />
        <IndicoLink text="Event page" url={`${event.baseUrl}/event/${event.indicoId}/manage`} />

        <div className="flex flex-row flex-wrap gap-2">
          {event.occupancy && event.occupancy.in && (
            <div
              className="flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs
                       font-medium text-primary dark:bg-darkSecondary dark:text-secondary"
            >
              <CheckCircleIcon className="mr-1 h-4 w-4" />
              <Typography variant="body1">{event.occupancy.in}</Typography>
            </div>
          )}
          <button
            className="flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs
                       font-medium text-primary dark:bg-darkSecondary dark:text-secondary"
            onClick={() => {
              navigate(`/event/${event.id}/participants`);
            }}
          >
            <UserGroupIcon className="mr-1 h-4 w-4" />
            <Typography variant="body1">Participants</Typography>
          </button>
          <button
            className="flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs
                       font-medium text-primary dark:bg-darkSecondary dark:text-secondary"
            onClick={() => {
              navigate(`/event/${event.id}/forms`);
            }}
          >
            <IconFeather className="mr-1 h-4 w-4" />
            <Typography variant="body1">Forms</Typography>
          </button>
        </div>
      </div>
      {(!registrations || (registrations.length === 0 && isSyncing)) && (
        <div className="mt-6">
          <TableSkeleton
            registrationCount={registrationCount}
            searchData={searchData}
            setSearchData={setSearchData}
          />
        </div>
      )}
      {registrations && registrations.length === 0 && !isSyncing && <NoRegistrationsBanner />}
      {registrations && registrations.length > 0 && (
        <Table
          registrations={registrations}
          event={event}
          searchData={searchData}
          setSearchData={setSearchData}
          onRowClick={async (p: Registration) => {
            await wait(50);
            navigate(`/event/${event.id}/${p.regformId}/${p.id}`, {state: {fromRegform: true}});
          }}
        />
      )}
    </div>
  );
}

export function EventTopNav({event}: {event?: Event}) {
  const navigate = useNavigate();
  const errorModal = useErrorModal();
  const confirmModal = useConfirmModal();

  if (!event) {
    return <TopNav backBtnText="Events" backNavigateTo="/" />;
  }

  const deleteEvent = async (id: number) => {
    try {
      await _deleteEvent(id);
    } catch (err: any) {
      errorModal({
        title: 'Something went wrong when deleting a registration form',
        content: err.message,
      });
    }
  };

  const settingsItems = [
    {
      text: 'Remove event',
      icon: <TrashIcon className="text-red-700 dark:text-red-500" />,
      onClick: () => {
        if (!event) {
          return;
        }

        confirmModal({
          title: 'Are you sure?',
          content: 'You can always re-add the event by scanning its QR code',
          confirmBtnText: 'Delete',
          onConfirm: async () => {
            await deleteEvent(event.id);
            navigate(`/`, {replace: true});
          },
        });
      },
    },
  ];

  return <TopNav backBtnText="Events" backNavigateTo="/" settingsItems={settingsItems} />;
}
