import {ExclamationCircleIcon, ArrowTopRightOnSquareIcon} from '@heroicons/react/20/solid';
import {CheckIcon} from '@heroicons/react/24/outline';
import {Button} from '../../Components/Tailwind';
import {LoadingIndicator} from '../../Components/Tailwind/LoadingIndicator';
import db, {Event, Regform, Registration} from '../../db/db';
import {HandleError} from '../../hooks/useError';
import {togglePayment as _togglePayment} from '../../utils/client';

async function resetPaidLoading(registration: Registration) {
  await db.registrations.update(registration.id, {isPaidLoading: 0});
}

async function markAsPaid(
  event: Event,
  regform: Regform,
  registration: Registration,
  handleError: HandleError
) {
  return await togglePayment(event, regform, registration, true, handleError);
}

export async function markAsUnpaid(
  event: Event,
  regform: Regform,
  registration: Registration,
  handleError: HandleError
) {
  return await togglePayment(event, regform, registration, false, handleError);
}

async function goToPaymentPage(
  event: Event,
  regform: Regform,
  registration: Registration,
  handleError: HandleError
) {
  try {
    window.open(
      `${event.baseUrl}/event/${event.indicoId}/registrations/${regform.indicoId}/checkout/?token=${registration.token}`,
      '_blank'
    );
  } catch (e: any) {
    handleError(e, 'Could not open payment page');
  }
  return true;
}

async function togglePayment(
  event: Event,
  regform: Regform,
  registration: Registration,
  paid: boolean,
  handleError: HandleError
) {
  await db.registrations.update(registration.id, {isPaidLoading: 1});
  const response = await _togglePayment(
    {
      serverId: event.serverId,
      eventId: event.indicoId,
      regformId: regform.indicoId,
      registrationId: registration.indicoId,
    },
    paid
  );

  if (response.ok) {
    return await db.registrations.update(registration.id, {
      state: response.data.state,
      isPaid: response.data.isPaid,
      isPaidLoading: 0,
    });
  } else {
    await resetPaidLoading(registration);
    handleError(response, 'Something went wrong when updating payment status');
  }
}

export function PaymentWarning({
  event,
  regform,
  registration,
  handleError,
}: {
  event: Event;
  regform: Regform;
  registration: Registration;
  handleError: HandleError;
}) {
  const onClick = async () => {
    await markAsPaid(event, regform, registration, handleError);
  };
  const onClickPaymentPageClick = async () => {
    await goToPaymentPage(event, regform, registration, handleError);
  };

  return (
    <div
      className="rounded-xl border border-yellow-300 bg-yellow-100 p-4 text-sm text-yellow-800 dark:border-yellow-800
                 dark:border-yellow-800 dark:bg-gray-800 dark:text-yellow-300"
      role="alert"
    >
      <div className="flex items-center gap-2">
        <ExclamationCircleIcon className="h-5 w-5 min-w-[1.25rem]" />
        <div>This registration has not been paid</div>
      </div>
      <div className="mt-4 flex flex-wrap justify-center gap-4">
        {!!registration.isPaidLoading && <LoadingIndicator />}
        {!registration.isPaidLoading && (
          <Button variant="warning" onClick={onClick}>
            <CheckIcon className="h-5 w-5" />
            Mark as paid
          </Button>
        )}
        {!registration.isPaidLoading && (
          <Button variant="warning" onClick={onClickPaymentPageClick}>
            <ArrowTopRightOnSquareIcon className="h-5 w-5" />
            Go to payment page
          </Button>
        )}
      </div>
    </div>
  );
}
