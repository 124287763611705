import {XMarkIcon} from '@heroicons/react/24/solid';
import {CheckIcon} from '@heroicons/react/24/solid';
import Button from './Button';
import {CheckedInFilter, CheckedInFilterData, SortButton} from './filters';
import Typography from './Typography';

type ParticipantSortKey = 'firstName' | 'lastName' | 'company' | 'registrationDate';

interface ParticipantSortFilterData {
  key: ParticipantSortKey;
  ascending: boolean;
}

export interface ParticipantFiltersInterface {
  checkedIn: CheckedInFilterData;
  sortBy: ParticipantSortFilterData;
}

export function makeDefaultParticipantFilterState() {
  return {
    checkedIn: {yes: false, no: false},
    sortBy: {key: 'lastName' as ParticipantSortKey, ascending: true},
  };
}

export function isDefaultParticipantFilterState(filters: ParticipantFiltersInterface) {
  const {checkedIn, sortBy} = filters;

  return Object.values(checkedIn).every(v => !v) && sortBy.key === 'lastName' && sortBy.ascending;
}

export function ParticipantFilters({
  filters,
  setFilters,
  onClose,
}: {
  filters: ParticipantFiltersInterface;
  setFilters: (v: ParticipantFiltersInterface) => void;
  onClose: () => void;
}) {
  const {checkedIn, sortBy} = filters;
  const reset = () => setFilters(makeDefaultParticipantFilterState());

  return (
    <div className="rounded-xl bg-gray-100 p-4 dark:bg-gray-700">
      <div className="relative flex flex-col gap-4">
        {!isDefaultParticipantFilterState(filters) && (
          <div className="absolute right-[-0.5rem] top-[-0.5rem]">
            <Typography variant="body2">
              <button
                className="flex items-center rounded-xl p-2 pl-3 transition-all active:bg-gray-300 dark:active:bg-gray-800"
                onClick={reset}
              >
                Clear
                <XMarkIcon className="h-6 w-6" />
              </button>
            </Typography>
          </div>
        )}
        <CheckedInFilter
          checkedIn={checkedIn}
          onChange={v => setFilters({...filters, checkedIn: v})}
        />
        <ParticipantSortFilter
          sortBy={sortBy}
          onChange={v => setFilters({...filters, sortBy: v})}
        />
        <div className="mt-6 flex justify-between">
          <Button onClick={onClose} className="basis-full justify-center" variant="success">
            <CheckIcon className="h-5 w-5" />
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
}

export function ParticipantSortFilter({
  sortBy,
  onChange,
}: {
  sortBy: ParticipantSortFilterData;
  onChange: (v: ParticipantSortFilterData) => void;
}) {
  const nameKeys = [
    {key: 'firstName', label: 'First Name'},
    {key: 'lastName', label: 'Last Name'},
    {key: 'company', label: 'Company'},
  ];
  const {key: activeKey, ascending} = sortBy;
  const dateActive = activeKey === 'registrationDate';

  return (
    <div className="flex flex-col gap-4">
      <Typography variant="body3" className="font-semibold uppercase text-gray-500">
        Sort by
      </Typography>
      <div className="flex flex-wrap gap-2">
        {nameKeys.map(({key, label}) => (
          <SortButton
            key={`sort-${key}`}
            active={key === activeKey}
            ascending={ascending}
            onClick={() =>
              onChange({
                key: key as ParticipantSortKey,
                ascending: key === activeKey ? !ascending : true,
              })
            }
          >
            {label}
          </SortButton>
        ))}
        <SortButton
          active={dateActive}
          ascending={ascending}
          onClick={() =>
            onChange({key: 'registrationDate', ascending: dateActive ? !ascending : true})
          }
        >
          Registration date
        </SortButton>
      </div>
    </div>
  );
}
