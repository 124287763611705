import {useEffect, useRef, useCallback, useState} from 'react';
import LoadingBanner from '../../Components/Tailwind/LoadingBanner';

function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

interface JsonKeyboardInterceptorProps {
  successCallback: (decodedText: string) => void;
  errorCallback?: (error: any) => void;
}

const JsonKeyboardInterceptor = ({
  successCallback,
  errorCallback,
}: JsonKeyboardInterceptorProps) => {
  // UI
  const [scanning, setScanning] = useState(false); // Determines if a Keyboard input is currently processed

  // intercept keyboard
  const documentRef = useRef<Document>(document);
  const dataRef = useRef('');

  const handleKeyDown = useCallback(
    (event: KeyboardEvent): void => {
      // log key
      // console.log(event.key);

      // check if key is single character
      if (event.key && typeof event.key === 'string' && event.key.length === 1) {
        if (
          (dataRef.current === '' && event.key === '{') ||
          (typeof dataRef.current === 'string' && dataRef.current.length > 0)
        ) {
          dataRef.current += event.key;
          setScanning(true);
        }
      }

      // check if current string is valid json
      if (dataRef.current && dataRef.current !== '' && isJsonString(dataRef.current)) {
        // use event handler
        const data = dataRef.current;
        successCallback(data);

        // clear everything for the next json string
        setScanning(false);
        dataRef.current = '';
      }

      // clear on enter/escape
      if (event.key === 'Enter' || event.key === 'Escape') {
        dataRef.current = '';
        setScanning(false);
      }
    },
    [dataRef, successCallback]
  );

  useEffect(() => {
    const documentRefValue = documentRef.current;
    documentRefValue.addEventListener('keydown', handleKeyDown);

    return () => {
      // const documentRefValue = documentRef.current;
      documentRefValue.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div className="text-center">
      {scanning && (
        <div className="py-4">
          <LoadingBanner text="Scanning..." />
        </div>
      )}
    </div>
  );
};

export default JsonKeyboardInterceptor;
