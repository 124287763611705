import './App.css';
import {useEffect, lazy, Suspense} from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Params,
  Outlet,
  useLocation,
  ScrollRestoration,
} from 'react-router-dom';
import BottomNav from './Components/BottomNav';
import KeyboardInterceptor from './Components/QrScanner/KeyboardInterceptor';
import Modal from './Components/Tailwind/Modal/Modal';
import db, {
  getEvent,
  getEvents,
  getRegistration,
  getRegform,
  getRegforms,
  getEventRegistrations,
  getEventParticipants,
  getServers,
  countRegistrations,
  countEventRegistrations,
  getServer,
  getParticipant,
} from './db/db';
import {useLogError} from './hooks/useError';
import useSettings from './hooks/useSettings';
import AuthRedirectPage from './pages/Auth/AuthRedirectPage';
import EventPage from './pages/event/EventPage';
import EventParticipantsPage from './pages/event/EventParticipantsPage';
import EventRegformPage from './pages/event/EventRegfromPage';
import Homepage from './pages/home/Homepage';
import LoadingFallback from './pages/LoadingFallback';
import {NotFoundPage} from './pages/NotFound';
import ParticipantPage from './pages/participant/ParticipantPage';
import RegformPage from './pages/regform/RegformPage';
import RegistrationPage from './pages/registration/RegistrationPage';
import SelfservicePage from './pages/selfservice/SelfservicePage';
import SelfserviceRegistrationPage from './pages/selfservice/SelfserviceRegistrationPage';
import SettingsPage from './pages/Settings';

// Expose the db instance as a global variable for easier debugging
(window as any).db = db;

const ScanPage = lazy(() => import('./pages/scan/Scan'));

function isNumeric(v?: string) {
  return v && /^[1-9]\d*$/.test(v);
}

const getNumericParams = (params: Params) => {
  const numeric = ['eventId', 'regformId', 'registrationId'];

  return Object.fromEntries(
    Object.entries(params).map(([key, value]) => {
      if (numeric.includes(key) && !isNumeric(value)) {
        throw new TypeError(`Invalid url parameter ${key}: <${value}>`);
      }
      return [key, Number(value)];
    })
  );
};

function RootPage() {
  const {pathname} = useLocation();
  const bottomNavVisible =
    pathname !== '/scan' && pathname !== '/auth/redirect' && !pathname.startsWith('/selfservice');

  return (
    <>
      <ScrollRestoration />
      <KeyboardInterceptor />
      <Outlet />
      {bottomNavVisible && <BottomNav />}
    </>
  );
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootPage />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: '/',
        element: <Homepage />,
        loader: async () => {
          const servers = await getServers();
          const events = await getEvents();
          const regforms = await getRegforms();
          return {servers, events, regforms};
        },
      },
      {
        path: '/event/:eventId',
        element: <EventPage />,
        loader: async ({params}) => {
          const {eventId} = getNumericParams(params);
          const event = await getEvent(eventId);
          const registrations = await getEventRegistrations(eventId);
          const registrationCount = await countEventRegistrations(eventId);
          return {event, registrations, registrationCount, params: {eventId}};
        },
      },
      {
        path: '/event/:eventId/forms',
        element: <EventRegformPage />,
        loader: async ({params}) => {
          const {eventId} = getNumericParams(params);
          const event = await getEvent(eventId);
          const regforms = await getRegforms(eventId);
          const registrationCount = await countEventRegistrations(eventId);
          return {event, regforms, registrationCount, params: {eventId}};
        },
      },
      {
        path: '/event/:eventId/participants',
        element: <EventParticipantsPage />,
        loader: async ({params}) => {
          const {eventId} = getNumericParams(params);
          const event = await getEvent(eventId);
          const participants = await getEventParticipants(eventId);
          const registrationCount = await countEventRegistrations(eventId);
          return {event, participants, registrationCount, params: {eventId}};
        },
      },
      {
        path: '/event/:eventId/participants/:participantId',
        element: <ParticipantPage />,
        loader: async ({params}) => {
          const {eventId, participantId} = getNumericParams(params);
          const event = await getEvent(eventId);
          const participant = await getParticipant({id: participantId, eventId});
          return {event, participant, params: {eventId, participantId}};
        },
      },
      {
        path: '/event/:id/:regformId',
        element: <RegformPage />,
        loader: async ({params}) => {
          const {id: eventId, regformId} = getNumericParams(params);
          const event = await getEvent(eventId);
          const regform = await getRegform({id: regformId, eventId});
          const registrationCount = await countRegistrations(regformId);
          return {event, regform, registrationCount, params: {eventId, regformId}};
        },
      },
      {
        path: '/event/:id/:regformId/:registrationId',
        element: <RegistrationPage />,
        loader: async ({params}) => {
          const {id: eventId, regformId, registrationId} = getNumericParams(params);
          const event = await getEvent(eventId);
          const regform = await getRegform({id: regformId, eventId});
          const registration = await getRegistration({id: registrationId, regformId});
          return {event, regform, registration, params: {eventId, regformId, registrationId}};
        },
      },
      {
        path: '/selfservice/',
        element: <SelfservicePage />,
        loader: async () => {
          const servers = await getServers();
          const events = await getEvents();
          const regforms = await getRegforms();
          return {servers, events, regforms};
        },
      },
      {
        path: '/selfservice/:serverId/:eventId/:regformId/:registrationId',
        element: <SelfserviceRegistrationPage />,
        loader: async ({params}) => {
          const {serverId, eventId, regformId, registrationId} = getNumericParams(params);
          const server = await getServer(serverId);
          // const event = await getEvent(eventId);
          // const regform = await getRegform({id: regformId, eventId});
          // const registration = await getRegistration({id: registrationId, regformId});
          return {
            server,
            params: {serverId, eventId, regformId, registrationId},
          };
        },
      },
      {
        path: '/settings',
        element: <SettingsPage />,
        loader: async () => {
          const events = await getEvents();
          return {events};
        },
      },
      {
        path: '/scan',
        element: (
          <Suspense fallback={<LoadingFallback />}>
            <ScanPage />
          </Suspense>
        ),
      },
      {
        path: '/auth/redirect',
        element: <AuthRedirectPage />,
      },
    ],
  },
]);

export default function App() {
  const {darkMode} = useSettings();
  const logError = useLogError();

  useEffect(() => {
    document.documentElement.classList.toggle('dark', darkMode);
  }, [darkMode]);

  useEffect(() => {
    function onError(event: ErrorEvent) {
      logError(event.error);
    }

    function onUnhandledRejection(event: PromiseRejectionEvent) {
      logError(event.reason);
    }

    window.addEventListener('error', onError);
    window.addEventListener('unhandledrejection', onUnhandledRejection);

    return () => {
      window.removeEventListener('error', onError);
      window.removeEventListener('unhandledrejection', onUnhandledRejection);
    };
  }, [logError]);

  return (
    <div className="h-full min-h-screen w-screen overflow-auto bg-gray-50 pb-32 dark:bg-gray-900">
      <RouterProvider router={router} />
      <Modal />
    </div>
  );
}
