import {useCallback, useRef, useState} from 'react';
import {PrinterIcon, TagIcon} from '@heroicons/react/20/solid';
import {Button} from '../../Components/Tailwind';
import {LoadingIndicator} from '../../Components/Tailwind/LoadingIndicator';
import {Event, Regform, Registration} from '../../db/db';
import {HandleError} from '../../hooks/useError';
import {createBadge} from '../../utils/client';
// import printJS from 'print-js';

export const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512): Blob => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
};

export function BadgeArea({
  event,
  regform,
  registration,
  handleError,
}: {
  event: Event;
  regform: Regform;
  registration: Registration;
  handleError: HandleError;
}) {
  const iFrameRef = useRef<HTMLIFrameElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const onClick = useCallback(
    async (templateId: number) => {
      // indicate loading
      setIsLoading(true);

      // get url
      const response = await createBadge(
        {
          serverId: event.serverId,
          eventId: event.indicoId,
          regformId: regform.indicoId,
          registrationId: registration.indicoId,
        },
        templateId
      );

      // check response
      if (response.ok) {
        try {
          console.log('Fetching worked', response);
          const blob = b64toBlob(response.data.document, 'application/pdf');
          const url = window.URL.createObjectURL(blob);
          // printJS({
          //   printable: url,
          //   type: 'pdf',
          //   onError: err => {
          //     console.log('Printing failed');
          //     console.log(err);
          //     errorModal({
          //       title: 'Badge could not be printed',
          //       content: `Please visit ${response.data.downloadUrl} to print the badge.`,
          //     });
          //     setIsLoading(false);
          //   },
          //   onLoadingEnd: () => {
          //     console.log('onLoadingEnd');
          //     setIsLoading(false);
          //   },
          //   onPrintDialogClose: () => {
          //     console.log('onPrintDialogClose');
          //     setIsLoading(false);
          //   },
          //   onIncompatibleBrowser: () => {
          //     console.log('onIncompatibleBrowser');
          //     setIsLoading(false);
          //   },
          // });
          if (iFrameRef.current && navigator && navigator.pdfViewerEnabled) {
            const frame = iFrameRef.current;
            frame.style.display = 'none';
            frame.onload = () => {
              setTimeout(() => {
                frame.focus();
                console.log('print started');
                if (frame.contentWindow === null) {
                  // open in new tab
                  window.open(url, '_blank');
                } else {
                  frame.contentWindow.print();
                }
              }, 1);
            };
            frame.src = url;
          } else {
            // open in new tab
            window.open(url, '_blank');
          }
        } catch {
          console.log('Error while printing', response);
          handleError({}, `Please visit ${response.data.downloadUrl} to print the badge.`);
        }
      } else {
        console.log('Error while fetching', response);

        handleError(
          response,
          response.err
            ? response.err.message
            : `Response status: ${response.status ? response.status : 'No response received'}`
        );
      }

      // loading finished
      setIsLoading(false);
      return;
    },
    [event, regform, registration, handleError, setIsLoading]
  );

  return (
    <div
      className={
        event.templates.length > 0
          ? 'rounded-xl border border-cyan-300 bg-cyan-100 p-4 text-sm text-cyan-800 dark:border-cyan-800 dark:border-cyan-800 dark:bg-gray-800 dark:text-cyan-300'
          : 'hidden'
      }
      role="alert"
    >
      <div className="flex items-center gap-2">
        <TagIcon className="h-5 w-5 min-w-[1.25rem]" />
        <div>Print a badge for this registration</div>
      </div>
      {isLoading ? (
        <div className="mt-4">
          <LoadingIndicator />
        </div>
      ) : (
        <div className="mt-4 flex flex-wrap justify-center gap-4">
          {event.templates.length > 0 &&
            event.templates.map(tpl => (
              <Button
                variant="default"
                onClick={() => onClick(tpl.id)}
                key={`print-badge-${tpl.id}`}
              >
                <PrinterIcon className="h-5 w-5" />
                {tpl.title}
              </Button>
            ))}
        </div>
      )}
      <iframe
        // className="mt-4 rounded-xl border border-cyan-300"
        ref={iFrameRef}
        height={0}
        width={200}
        title="Print badge"
      />
      {/* {printUrl && <iframe height={200} width={200} src={printUrl} title="Print badge" />} */}
    </div>
  );
}
