import db, {Event, Participant, Regform, Registration} from '../../db/db';
import {HandleError} from '../../hooks/useError';
import {checkInIndicoParticipant, checkInIndicoRegistration} from '../../utils/client';
import {playVibration} from '../../utils/haptics';
import {playSound} from '../../utils/sound';

async function resetRegistrationCheckedInLoading(registration: Registration) {
  await db.registrations.update(registration.id, {checkedInLoading: 0});
}

async function resetParticipantCheckedInLoading(participant: Participant) {
  await db.participants.update(participant.id, {checkedInLoading: 0});
}

async function updateCheckinState(
  regform: Regform,
  registration: Registration,
  newCheckInState: boolean
) {
  return db.transaction('readwrite', db.regforms, db.registrations, async () => {
    await resetRegistrationCheckedInLoading(registration);
    await db.registrations.update(registration.id, {
      checkedIn: newCheckInState,
      checkedInLoading: 0,
    });
    const slots = registration.occupiedSlots;
    const checkedInCount = regform.checkedInCount + (newCheckInState ? slots : -slots);
    await db.regforms.update(regform.id, {checkedInCount});
  });
}

async function updateParticipantCheckinState(
  event: Event,
  participant: Participant,
  newCheckInState: boolean
) {
  return db.transaction('readwrite', db.events, db.participants, async () => {
    await resetParticipantCheckedInLoading(participant);
    await db.participants.update(participant.id, {
      checkedIn: newCheckInState,
      checkedInLoading: 0,
    });
    const checkedInCount = event.checkedInCount + (newCheckInState ? 1 : -1);
    await db.events.update(event.id, {checkedInCount});
  });
}

export async function checkInRegistration(
  event: Event,
  regform: Regform,
  registration: Registration,
  newCheckInState: boolean,
  sound: string,
  hapticFeedback: boolean,
  handleError: HandleError
) {
  await db.registrations.update(registration.id, {checkedInLoading: 1});
  const response = await checkInIndicoRegistration(
    {
      serverId: event.serverId,
      eventId: event.indicoId,
      regformId: regform.indicoId,
      registrationId: registration.indicoId,
    },
    newCheckInState
  );

  if (response.ok) {
    await updateCheckinState(regform, registration, newCheckInState);
    if (newCheckInState) {
      playSound(sound);
      if (hapticFeedback) {
        playVibration.success();
      }
    }
  } else {
    await resetRegistrationCheckedInLoading(registration);
    handleError(response, 'Something went wrong when updating check-in status');
  }
}
export async function checkInParticipant(
  event: Event,
  participant: Participant,
  newCheckInState: boolean,
  sound: string,
  hapticFeedback: boolean,
  handleError: HandleError
) {
  await db.participants.update(participant.id, {checkedInLoading: 1});
  const response = await checkInIndicoParticipant(
    {
      serverId: event.serverId,
      eventId: event.indicoId,
      participantUuid: participant.uuid,
    },
    newCheckInState
  );

  if (response.ok) {
    await updateParticipantCheckinState(event, participant, newCheckInState);
    if (newCheckInState) {
      playSound(sound);
      if (hapticFeedback) {
        playVibration.success();
      }
    }
  } else {
    await resetParticipantCheckedInLoading(participant);
    handleError(response, 'Something went wrong when updating check-in status');
  }
}
