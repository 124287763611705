import {useEffect, useState} from 'react';
import {useLoaderData, useNavigate} from 'react-router-dom';
import {CalendarDaysIcon} from '@heroicons/react/20/solid';
import {UserIcon} from '@heroicons/react/24/solid';
import {Typography} from '../../Components/Tailwind';
import {makeDefaultFilterState} from '../../Components/Tailwind/filters';
import IndicoLink from '../../Components/Tailwind/IndicoLink';
import Title from '../../Components/Tailwind/PageTitle';
import ParticipantsTable, {
  ParticipantsSearchData,
} from '../../Components/Tailwind/ParticipantsTable';
import {Event, getEvent, useLiveEvent, useLiveEventParticipants, Participant} from '../../db/db';
import {useHandleError} from '../../hooks/useError';
import {formatDatetime} from '../../utils/date';
import {wait} from '../../utils/wait';
import {syncEvent, syncParticipants} from '../Events/sync';
import {NotFoundBanner} from '../NotFound';
import {EventTopNav} from './EventPage';

interface Params {
  eventId: number;
}

export default function EventParticipantsPage() {
  const loaderData = useLoaderData() as {
    event?: Event;
    participants: Participant[];
    params: Params;
    registrationCount: number;
  };

  const {eventId} = loaderData.params;
  const event = useLiveEvent(eventId, loaderData.event);
  // Participants are preloaded in case there is a lot of them (10k+) as this
  // would make this page too slow to load w/o visual feedback. Instead,
  // the page is loaded immediately and a table skeleton is shown while the
  // registrations are loading.
  const participants = useLiveEventParticipants(eventId, loaderData.participants);

  return (
    <>
      <EventTopNav event={event} />
      <EventParticipantsPageContent
        eventId={eventId}
        event={event}
        participants={participants}
        registrationCount={loaderData.registrationCount}
      />
    </>
  );
}

function EventParticipantsPageContent({
  eventId,
  event,
  participants,
  registrationCount,
}: {
  eventId: number;
  event?: Event;
  participants?: Participant[];
  registrationCount: number;
}) {
  const navigate = useNavigate();
  const handleError = useHandleError();
  const [isSyncing, setIsSyncing] = useState(false);
  const savedFilters = JSON.parse(localStorage.getItem('participantFilters') || '{}')[eventId];
  const [searchData, _setSearchData] = useState({
    searchValue: savedFilters?.searchValue || '',
    filters: savedFilters?.filters || makeDefaultFilterState(),
  });

  const setSearchData = (data: ParticipantsSearchData) => {
    _setSearchData(data);
    const events = JSON.parse(localStorage.getItem('participantFilters') || '{}');
    events[eventId] = data;
    localStorage.setItem('participantFilters', JSON.stringify(events));
  };

  useEffect(() => {
    const controller = new AbortController();
    async function _sync() {
      const event = await getEvent(eventId);
      if (!event) {
        return;
      }
      await syncEvent(event, controller.signal, handleError);
      await syncParticipants(event, controller.signal, handleError);
    }

    async function sync() {
      setIsSyncing(true);
      try {
        await _sync();
      } catch (err: any) {
        handleError(err, 'Something went wrong when fetching updates');
      } finally {
        if (!controller.signal.aborted) {
          setIsSyncing(false);
        }
      }
    }

    sync();
    return () => controller.abort();
  }, [eventId, setIsSyncing, handleError]);

  if (!event) {
    return <NotFoundBanner text="Event not found" icon={<CalendarDaysIcon />} />;
  }

  return (
    <div className="mx-auto px-4" style={{maxWidth: 1600}}>
      <div className="flex flex-col items-center gap-2">
        <CalendarDaysIcon className="w-16 text-blue-600 dark:text-blue-700" />
        <Title title={event.title} />
        <IndicoLink text="Event page" url={`${event.baseUrl}/event/${event.indicoId}/manage`} />
        <span
          className="mr-2 w-fit rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium
                       text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300"
        >
          {formatDatetime(event.date)}
        </span>
      </div>
      {participants && participants.length === 0 && !isSyncing && <NoParticipantsBanner />}
      {participants && participants.length > 0 && (
        <ParticipantsTable
          participants={participants}
          event={event}
          searchData={searchData}
          setSearchData={setSearchData}
          onRowClick={async (p: Participant) => {
            await wait(50);
            navigate(`/event/${event.id}/participants/${p.id}`);
          }}
        />
      )}
    </div>
  );
}

export function NoParticipantsBanner() {
  return (
    <div className="mx-4 mt-10 rounded-xl bg-gray-100 px-3 pb-2 dark:bg-gray-800">
      <div className="flex flex-col items-center justify-center gap-2 rounded-xl px-6 pb-12 pt-10">
        <UserIcon className="w-14 text-gray-500" />
        <Typography variant="h3" className="text-center">
          There are no participants
        </Typography>
      </div>
    </div>
  );
}
