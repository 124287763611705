import {NavigateFunction} from 'react-router-dom';
import {OAuth2Client, generateCodeVerifier} from '@badgateway/oauth2-client';
import {ErrorModalFunction, SuccessModalFunction} from '../../context/ModalContextProvider';
import db, {
  addEventIfNotExists,
  addRegistration,
  addRegformIfNotExists,
  getRegistrationByUuid,
  getRegform,
  getServer,
  updateRegistration,
  updateParticipant,
  addParticipant,
} from '../../db/db';
import {HandleError} from '../../hooks/useError';
import {
  BadgeValidationParameters,
  getRegistrationByUuid as getRegistration,
  validateBadge,
} from '../../utils/client';
import {discoveryEndpoint, QRCodeBadgeData, redirectUri} from '../Auth/utils';
import {QRCodeEventData, QRCodeRegistrationData} from '../Auth/utils';

async function startOAuthFlow(data: QRCodeEventData, errorModal: ErrorModalFunction) {
  const {
    server: {baseUrl, clientId, scope},
  } = data;
  const client = new OAuth2Client({
    server: baseUrl,
    clientId: clientId,
    discoveryEndpoint: discoveryEndpoint,
    fetch: fetch.bind(window),
    // XXX Need to manually specify, otherwise this will be 'client_secret_basic' which
    // doesn't work with Indico even though it is listed in '.well-known/oauth-authorization-server'
    authenticationMethod: 'client_secret_post',
  });

  const codeVerifier = await generateCodeVerifier();
  // Store the eventData in the browser's session storage. This is used later to verify the code challenge
  sessionStorage.setItem('eventData', JSON.stringify(data));
  sessionStorage.setItem('codeVerifier', codeVerifier);

  try {
    // Redirect the user to the Authentication Server (OAuth2 Server)
    // Which will redirect the user back to the redirectUri (Back to the App)
    document.location = await client.authorizationCode.getAuthorizeUri({
      // URL in the app that the user should get redirected to after authenticating
      redirectUri,
      codeVerifier,
      scope: [scope],
    });
  } catch (err: any) {
    errorModal({title: 'OAuth authorization failed', content: err.message});
  }
}

export async function handleEvent(
  data: QRCodeEventData,
  errorModal: ErrorModalFunction,
  navigate: NavigateFunction
) {
  // Check if the serverData is already in indexedDB
  const server = await getServer({baseUrl: data.server.baseUrl});
  if (server) {
    // No need to perform authentication
    let id!: number;
    const {eventId: eventIndicoId, regformId: regformIndicoId, title, date, regformTitle} = data;
    await db.transaction('readwrite', db.events, db.regforms, async () => {
      id = await addEventIfNotExists({
        indicoId: eventIndicoId,
        serverId: server.id,
        baseUrl: server.baseUrl,
        title,
        date,
        templates: [],
        rooms: [],
        accessZones: [],
        regforms: [],
        participantTags: [],
      });
      await addRegformIfNotExists({indicoId: regformIndicoId, eventId: id, title: regformTitle});
    });

    navigate(`/event/${id}`, {replace: true});
  } else {
    // Perform OAuth2 Authorization Code Flow
    // TODO: test this
    await startOAuthFlow(data, errorModal);
  }
}

export async function handleRegistration(
  data: QRCodeRegistrationData,
  errorModal: ErrorModalFunction,
  handleError: HandleError,
  navigate: NavigateFunction,
  autoCheckin: boolean
) {
  const server = await db.servers.get({baseUrl: data.serverUrl});
  if (!server) {
    errorModal({
      title: 'The server of this registration does not exist',
      content: 'Scan an event QR code first and try again.',
    });
    return;
  }

  const registration = await getRegistrationByUuid(data.checkinSecret);

  if (registration) {
    const regform = await getRegform(registration.regformId);
    if (!regform) {
      errorModal({
        title: 'The registration form of this registration does not exist',
        content: 'Scan an event QR code first and try again.',
      });
      return;
    }

    const registrationPage = `/event/${regform.eventId}/${regform.id}/${registration.id}`;
    // console.log(`navigate to: ${registrationPage}, autoCheckin: ${autoCheckin}`);
    navigate(registrationPage, {
      replace: true,
      state: {autoCheckin},
    });
  } else {
    const response = await getRegistration({
      serverId: server.id,
      uuid: data.checkinSecret,
    });

    if (response.ok) {
      const {id, eventId, regformId, ...rest} = response.data;
      const event = await db.events.get({indicoId: eventId, serverId: server.id});
      if (!event) {
        errorModal({
          title: 'The event of this registration does not exist',
          content: 'Scan an event QR code first and try again.',
        });
        return;
      }

      const regform = await db.regforms.get({indicoId: regformId, eventId: event.id});
      if (!regform) {
        errorModal({
          title: 'The registration form of this registration does not exist',
          content: 'Scan an event QR code first and try again.',
        });
        return;
      }

      let registrationId;
      await db.transaction('readwrite', db.registrations, async () => {
        const registration = await db.registrations.get({indicoId: id, regformId: regform.id});
        if (registration) {
          await updateRegistration(registration.id, response.data);
          registrationId = registration.id;
        } else {
          registrationId = await addRegistration({indicoId: id, regformId: regform.id, ...rest});
        }
      });

      const registrationPage = `/event/${regform.eventId}/${regform.id}/${registrationId}`;
      console.log('navigate', registrationPage);
      navigate(registrationPage, {
        replace: true,
        state: {autoCheckin, fromScan: true},
      });
    } else {
      handleError(response, 'Could not fetch registration data');
    }
  }
}

export async function handleSelfserviceRegistration(
  data: QRCodeRegistrationData,
  errorModal: ErrorModalFunction,
  handleError: HandleError,
  navigate: NavigateFunction
) {
  const server = await db.servers.get({baseUrl: data.serverUrl});

  if (!server) {
    errorModal({
      title: 'The server for this ticket does not exist.',
      content: 'Please contact the counter for further assistance.',
    });
    return;
  }

  // fetch data from server
  const response = await getRegistration({
    serverId: server.id,
    uuid: data.checkinSecret,
  });

  // validate response is ok,
  if (response.ok) {
    const {id, eventId, regformId, checkedIn, state} = response.data;

    const selfserviceRegistrationPage = `/selfservice/${server.id}/${eventId}/${regformId}/${id}`;
    console.log('navigate', selfserviceRegistrationPage);
    navigate(selfserviceRegistrationPage, {
      replace: true,
      state: {checkedIn, registrationState: state, fromScan: true},
    });
  } else {
    handleError(
      response,
      'Check in not possible. Please contact the counter for further assistance.'
    );
  }
}

export async function handleBadge(
  data: QRCodeBadgeData,
  validationParams: BadgeValidationParameters,
  errorModal: ErrorModalFunction,
  successModal: SuccessModalFunction,
  handleError: HandleError,
  navigate: NavigateFunction
) {
  const server = await db.servers.get({baseUrl: data.serverUrl});

  if (!server) {
    errorModal({
      title: 'The server for this ticket does not exist.',
      content: 'Please contact the counter for further assistance.',
    });
    return;
  }

  // fetch data from server
  const response = await validateBadge(
    {
      serverId: server.id,
      eventId: data.eventId,
      badgeUuid: data.badgeId,
    },
    validationParams
  );

  // validate response is ok,
  if (response.ok) {
    const {
      eventId,
      accessGranted,
      reasonLabel,
      accessZoneLabel,
      roomLabel,
      logout,
      participant: fetchedParticipant,
    } = response.data;

    const event = await db.events.get({indicoId: eventId, serverId: server.id});
    if (!event) {
      errorModal({
        title: 'The event of this badge does not exist',
        content: 'Scan an event QR code first and try again.',
      });
      return;
    }

    let participantId;
    await db.transaction('readwrite', db.participants, async () => {
      const participant = await db.participants.get({
        uuid: fetchedParticipant.uuid,
        eventId: event.id,
      });
      if (participant) {
        await updateParticipant(participant.id, fetchedParticipant);
        participantId = participant.id;
      } else {
        participantId = await addParticipant({eventId: event.id, ...fetchedParticipant});
      }
    });

    const participantPage = `/event/${event.id}/participants/${participantId}`;
    const navigateToParticipant = () => {
      navigate(participantPage, {
        replace: true,
        state: {fromScan: true, accessGranted, reasonLabel},
      });
    };
    let content = [];
    if (reasonLabel) {
      content.push(reasonLabel);
    }
    if (accessZoneLabel) {
      content.push(`Zone: ${accessZoneLabel}`);
    }
    if (roomLabel) {
      content.push(`Room: ${roomLabel}`);
    }
    if (accessGranted === true) {
      console.log('success');
      console.log('success');
      successModal({
        title: 'Access granted',
        content: content.join(', '),
        actionBtnText: 'View participant',
        onAction: navigateToParticipant,
      });
    } else if (logout === true) {
      console.log('logout');
      successModal({
        title: 'Logout successful',
        content: content.join(', '),
        actionBtnText: 'View participant',
        onAction: navigateToParticipant,
      });
    } else {
      errorModal({
        title: 'Access denied',
        content: content.join(', '),
        actionBtnText: 'View participant',
        onAction: navigateToParticipant,
      });
    }
  } else {
    errorModal({
      title: 'Badge could not be validated',
      content: 'Please check your internet connection and try again.',
    });
    handleError(
      response,
      'Check in not possible. Please contact the counter for further assistance.'
    );
  }
}
