import {CheckIcon} from '@heroicons/react/20/solid';

export default function SuccessBanner({msg, title}: {msg: string; title?: string}) {
  return (
    <div
      className="b-t-5 b-t-green-400 flex items-center gap-5 rounded-md p-5 text-green-800 dark:border-green-800 dark:bg-green-800/40 dark:text-green-400"
      role="alert"
    >
      <CheckIcon className="h-12 w-12" />
      <div className="flex flex-col">
        {title && <div className="text-lg font-bold">{title}</div>}
        <div className="font-medium">{msg}</div>
      </div>
    </div>
  );
}
