import {useMemo} from 'react';
import {Event, Regform, Registration} from '../../db/db';
import {HandleError} from '../../hooks/useError';

export function TagsArea({
  registration,
}: {
  event: Event;
  regform: Regform;
  registration: Registration;
  handleError: HandleError;
}) {
  const tags = useMemo(() => {
    console.log(registration);
    const tags = [];
    if (Array.isArray(registration.tags)) {
      for (const label of registration.tags) {
        let bgColor = 'gray-500';
        let textColor = 'white';
        if (['Bass', 'Metrodrum', 'Vocals', 'Bodrum', 'Gitarre'].includes(label)) {
          bgColor = `feinmetall-${label.toLowerCase()}`;
        }
        if (['Bodrum', 'Gitarre'].includes(label)) {
          textColor = 'black';
        }
        tags.push({
          label,
          bgColor,
          textColor,
        });
      }
    }
    return tags;
  }, [registration]);

  return (
    <div className="align-stretch flex flex-col flex-wrap justify-center gap-4 text-center md:flex-row">
      {tags &&
        tags.map(tag => (
          <div
            key={`registration-${registration.id}-tag-${tag.label}`}
            className={`p-4 md:px-8 bg-${tag.bgColor} rounded-xl text-${tag.textColor} border border-gray-500 dark:border-gray-700`}
          >
            {tag.label}
          </div>
        ))}
    </div>
  );
}
