import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import LoadingBanner from '../../Components/Tailwind/LoadingBanner';
import {useHandleError} from '../../hooks/useError';
import {useErrorModal, useSuccessModal} from '../../hooks/useModal';
import useSettings from '../../hooks/useSettings';
import {
  validateEventData,
  parseQRCodeRegistrationData,
  parseQRCodeBadgeData,
} from '../../pages/Auth/utils';
import {
  handleBadge,
  handleEvent,
  handleRegistration,
  handleSelfserviceRegistration,
} from '../../pages/scan/scan';
import {camelizeKeys} from '../../utils/case';
import {useIsOffline} from '../../utils/client';
import JsonKeyboardInterceptor from './QrKeyboardInterceptor';

const KeyboardInterceptor = () => {
  const [processing, setProcessing] = useState(false); // Determines if a QR Code is being processed
  const {autoCheckin, logout, roomUuid, accessZoneUuid, selfserviceCheckin} = useSettings();
  const navigate = useNavigate();
  const errorModal = useErrorModal();
  const successModal = useSuccessModal();
  const handleError = useHandleError();
  const offline = useIsOffline();

  async function processCode(decodedText: string) {
    if (processing) {
      // Prevent multiple scans at the same time
      return;
    }
    setProcessing(true);

    let scannedData;
    try {
      scannedData = JSON.parse(decodedText);
    } catch (e: any) {
      errorModal({title: 'Error parsing the QRCode data', content: e.message});
      return;
    }

    scannedData = camelizeKeys(scannedData);
    if (selfserviceCheckin === true) {
      // selfservice checkin is only available when online
      if (offline) {
        errorModal({
          title: 'You are offline',
          content: 'Please contact the counter to check in',
        });
        return;
      }

      const parsedData = parseQRCodeRegistrationData(scannedData);
      console.log(parsedData);
      if (parsedData) {
        try {
          console.log('handleSelfserviceRegistration', parsedData);
          await handleSelfserviceRegistration(parsedData, errorModal, handleError, navigate);
        } catch (e: any) {
          console.log(e);
          errorModal({
            title: 'Error reading ticket',
            content: 'Please contact the counter for further assistance.',
          });
          console.log(e.message);
        }
      } else {
        errorModal({
          title: 'Ticket cannot be read',
          content: 'Please contact the counter for further assistance.',
        });
      }
    } else {
      if (validateEventData(scannedData)) {
        if (offline) {
          errorModal({
            title: 'You are offline',
            content: 'Internet connection is required to add a registration form.',
          });
          return;
        }

        try {
          await handleEvent(scannedData, errorModal, navigate);
        } catch (e: any) {
          errorModal({title: 'Error processing QR code', content: e.message});
        }
        return;
      }

      const parsedBadgeData = parseQRCodeBadgeData(scannedData);
      if (parsedBadgeData) {
        try {
          await handleBadge(
            parsedBadgeData,
            {
              logout,
              accessZoneUuid,
              roomUuid,
            },
            errorModal,
            successModal,
            handleError,
            navigate
          );
        } catch (e: any) {
          errorModal({title: 'Error processing QR code', content: e.message});
        }
      }

      const parsedTicketData = parseQRCodeRegistrationData(scannedData);
      if (parsedTicketData) {
        try {
          console.log('handleRegistration', parsedTicketData);
          await handleRegistration(
            parsedTicketData,
            errorModal,
            handleError,
            navigate,
            autoCheckin
          );
        } catch (e: any) {
          errorModal({title: 'Error processing QR code', content: e.message});
        }
      }

      if (!parsedBadgeData && !parsedTicketData) {
        errorModal({
          title: 'QR code data is not valid',
          content: 'Please scan the code again.',
        });
      }
    }
  }

  const onScanResult = async (decodedText: string) => {
    try {
      console.log(decodedText);
      await processCode(decodedText);
    } catch (e: any) {
      errorModal({title: 'Error processing QR code', content: e.message});
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div>
      <JsonKeyboardInterceptor
        successCallback={onScanResult}
        errorCallback={err => {
          console.log(err);
        }}
      />
      {processing && <LoadingBanner text="Processing..." />}
    </div>
  );
};

export default KeyboardInterceptor;
