import {useCallback, useEffect, useRef, useState} from 'react';
import {useLoaderData, useLocation, useNavigate} from 'react-router-dom';
import {useTimer} from 'react-timer-hook';
import {Typography} from '../../Components/Tailwind';
import ErrorBanner from '../../Components/Tailwind/ErrorBanner';
import LoadingBanner from '../../Components/Tailwind/LoadingBanner';
import {LoadingIndicator} from '../../Components/Tailwind/LoadingIndicator';
import SuccessBanner from '../../Components/Tailwind/SuccessBanner';
import {Event, getEventByServerAndIndicoId, Server, useLiveIndicoEvent} from '../../db/db';
import {useHandleError} from '../../hooks/useError';
import {useErrorModal} from '../../hooks/useModal';
import {
  checkInIndicoRegistration,
  getRegistration as fetchRegistration,
  IndicoRegistration,
} from '../../utils/client';
import {useIsOffline} from '../../utils/client';
import {createBadge} from '../../utils/client';
import {b64toBlob} from '../registration/badge';

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

interface Params {
  serverId: number;
  eventId: number;
  regformId: number;
  registrationId: string;
}

export default function SelfserviceRegistrationPage() {
  const data = useLoaderData() as {
    event?: Event;
    server?: Server;
    params: Params;
  };

  const {serverId, eventId, regformId, registrationId} = data.params;

  // const {data, error} = useSWR(eventId ? '/api/meta/event/' + eventId : null, fetcher);
  const event = useLiveIndicoEvent(serverId, eventId, data.event);
  // const regform = useLiveRegform({id: regformId, eventId}, data.regform);
  // const registration = useLiveRegistration({id: registrationId, regformId}, data.registration);

  return (
    <>
      <SelfserviceRegistrationPageContent
        serverId={serverId}
        eventId={eventId}
        regformId={regformId}
        registrationId={registrationId}
        event={event}
      />
    </>
  );
}

function SelfserviceRegistrationPageContent({
  serverId,
  eventId,
  regformId,
  registrationId,
  event,
}: {
  serverId: number;
  eventId: number;
  regformId: number;
  registrationId: string;
  event?: Event;
}) {
  const navigate = useNavigate();
  const {state} = useLocation();
  const [registration, setRegistration] = useState<IndicoRegistration | undefined>(undefined);
  const [checkinNecessary, setCheckinNecessary] = useState(state?.autoCheckin ?? false);
  const offline = useIsOffline();
  const errorModal = useErrorModal();
  const showCheckedInWarning = useRef<boolean>(true);
  const badgePrinted = useRef<boolean>(false);
  const handleError = useHandleError();

  // timer to navigate back to scan page after 30 seconds
  const navigateToSelfservicePage = useCallback(() => {
    navigate('/selfservice');
  }, [navigate]);
  const time = new Date();
  time.setSeconds(time.getSeconds() + 10); // 10 seconds
  const {isRunning, start} = useTimer({
    expiryTimestamp: time,
    onExpire: navigateToSelfservicePage,
    autoStart: false,
  });

  // printing
  const iFrameRef = useRef<HTMLIFrameElement | null>(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const printBadge = useCallback(
    async (templateId: number) => {
      // indicate loading
      setIsPrinting(true);

      // get url
      const response = await createBadge(
        {
          serverId,
          eventId: eventId,
          regformId,
          registrationId,
        },
        templateId
      );

      // check response
      if (response.ok) {
        try {
          console.log('Fetching worked', response);
          const blob = b64toBlob(response.data.document, 'application/pdf');
          const url = window.URL.createObjectURL(blob);
          if (iFrameRef.current && navigator && navigator.pdfViewerEnabled) {
            const frame = iFrameRef.current;
            frame.style.display = 'none';
            frame.onload = () => {
              setTimeout(() => {
                frame.focus();
                console.log('print started');
                if (frame.contentWindow === null) {
                  // open in new tab
                  window.open(url, '_blank');
                } else {
                  frame.contentWindow.print();
                }
              }, 1);
            };
            frame.src = url;

            // wait a second
            await sleep(15000);

            // printing finished
            setIsPrinting(false);

            // return success
            return true;
          } else {
            setErrorMessage(
              'Your Badge could not be printed. Please contact the counter for further assistance.'
            );
          }
        } catch {
          console.log('Error while printing', response);
          setErrorMessage(
            'Your Badge could not be printed. Please contact the counter for further assistance.'
          );
        }
      } else {
        console.log('Error while fetching', response);
        setErrorMessage(
          'Your Badge could not be generated. Please contact the counter for further assistance.'
        );
      }

      // printing finished
      setIsPrinting(false);

      return false;
    },
    [serverId, eventId, regformId, registrationId, setErrorMessage, setIsPrinting]
  );

  useEffect(() => {
    console.log('useEffect 1 called');
    // remove autoCheckin and fromScan from location state
    if (state?.autoCheckin !== undefined || state?.fromScan !== undefined) {
      const {checkedIn, registrationState, fromScan, ...rest} = state || {};
      // mark checkin necessary checkin, if scanner is used
      if (checkedIn === false && registrationState === 'complete') {
        setCheckinNecessary(true);
        showCheckedInWarning.current = false;
      } else {
        // start timer
        start();
      }

      navigate('.', {replace: true, state: rest});
    }
  }, [navigate, state, registrationId, setCheckinNecessary]);

  const performCheckin = useCallback(
    async (serverId: number, eventId: number, regformId: number, registrationId: string) => {
      if (offline) {
        errorModal({title: 'You are offline', content: 'Check-in requires an internet connection'});
        return;
      }

      try {
        const response = await checkInIndicoRegistration(
          {
            serverId,
            eventId,
            regformId,
            registrationId: String(registrationId),
          },
          true
        );
        if (response.ok) {
          return response.data;
        } else {
          handleError(response, 'Check in not possible, please contact the counter.');
        }
      } catch (err: any) {
        handleError(err, 'Check in not possible, please contact the counter.');
      } finally {
      }
    },
    [offline, errorModal, handleError]
  );

  useEffect(() => {
    console.log('useEffect 2 called');
    // fetch registration
    const controller = new AbortController();

    async function sync() {
      if (checkinNecessary === true) {
        setCheckinNecessary(false);
        const registration = await performCheckin(serverId, eventId, regformId, registrationId);

        // const event = await fetchEvent({serverId, eventId})
        const event = await getEventByServerAndIndicoId(serverId, eventId);

        if (
          event &&
          event.templates &&
          Array.isArray(event.templates) &&
          event.templates.length > 0
        ) {
          // print first template
          badgePrinted.current = await printBadge(event.templates[0].id);
        } else if (event === undefined) {
          console.log(event);
          setErrorMessage(
            'Could not find template to print badge. Please ask the counter for further assistance.'
          );
        } else {
          console.log(event);
          setErrorMessage(
            'There is no Badge to print. Please ask the counter for further assistance.'
          );
        }

        // start timer
        start();

        // update registration
        setRegistration(registration);
      } else {
        // fetch registration to display everything
        const response = await fetchRegistration(
          {
            serverId,
            eventId,
            regformId,
            registrationId: String(registrationId),
          },
          {
            signal: controller.signal,
          }
        );
        console.log(response);
        console.log(response.data);
        console.log(response.status);

        if (response.ok) {
          const sm = response.data;
          setRegistration(sm);
        } else if (response.aborted !== true) {
          setErrorMessage(
            'Something went wrong while retrieving your data. Please ask the counter for further assistance.'
          );
        }
      }
    }

    sync().catch(err => {
      setErrorMessage(
        'Your badge could not be printed, please contact the counter for further assistance.'
      );
    });

    return () => controller.abort();
  }, [
    serverId,
    eventId,
    regformId,
    registrationId,
    event,
    setErrorMessage,
    checkinNecessary,
    offline,
    performCheckin,
    printBadge,
  ]);

  return (
    <>
      <div className="-mb-32 flex min-h-screen flex-col items-center justify-center">
        <div className="h-2.5 w-full bg-gray-200 dark:bg-gray-700">
          <div
            className="h-2.5 rounded-r-full bg-blue-600"
            style={{width: `${isRunning ? '100' : '0'}%`, transition: 'width 10s linear'}}
          ></div>
        </div>
        <div
          className="m-auto mx-4 flex items-center justify-center
               rounded-xl bg-gray-100 p-6 text-center dark:bg-gray-800"
        >
          <div className="h-100 flex flex-col justify-center gap-5">
            {(registration === undefined || !event) && <LoadingIndicator />}
            {registration && <Typography variant="h2">Welcome</Typography>}
            {registration && registration.fullName && (
              <Typography variant="h1" className="font-semibold">
                {registration.fullName}
              </Typography>
            )}
            <iframe ref={iFrameRef} height={0} width={200} title="Print badge" />
            <div className="flex flex-col items-center gap-5">
              {registration && registration.checkedIn && showCheckedInWarning.current !== false && (
                <ErrorBanner
                  title="You are already checked in. Please use your existing badge."
                  msg="If you have lost your badge, please ask the counter for further assistance."
                />
              )}
              {registration && registration.state === 'unpaid' && (
                <ErrorBanner
                  title="Your ticket has not been paid."
                  msg="Please use the link in the registration confirmation email to pay by credit card or pay cash at the counter."
                />
              )}
              {registration && !['unpaid', 'complete'].includes(registration.state) && (
                <ErrorBanner
                  title="Your ticket is not valid."
                  msg="Please ask the counter for further assistance."
                />
              )}
              {errorMessage !== '' && <ErrorBanner msg={errorMessage} />}
            </div>

            {registration &&
              registration.state === 'complete' &&
              registration.checkedIn === true &&
              badgePrinted.current === true && <SuccessBanner msg="You are checked in." />}
            {isPrinting && <LoadingBanner text="Your badge is printing" />}

            {/* <div className="mt-2 text-white">{JSON.stringify(registration)}</div> */}
          </div>
        </div>
      </div>
    </>
  );
}
