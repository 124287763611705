import {useEffect} from 'react';
import {QrCodeIcon} from '@heroicons/react/20/solid';
import {Typography} from '../../Components/Tailwind';
import db from '../../db/db';
import {useHandleError} from '../../hooks/useError';
import {syncEvents} from '../Events/sync';

export default function SelfservicePage() {
  return (
    <>
      <SelfserviceContent />
    </>
  );
}

function SelfserviceContent() {
  const handleError = useHandleError();

  useEffect(() => {
    const controller = new AbortController();

    async function sync() {
      const events = await db.events.toArray();
      await syncEvents(events, controller.signal, handleError);
    }

    sync().catch(err => handleError(err, 'Something went wrong when updating events'));
    return () => controller.abort();
  }, [handleError]);

  return <ScanTicketBanner />;
}

function ScanTicketBanner() {
  return (
    <div className="-mb-32 flex min-h-screen items-center justify-center">
      <div
        className="m-auto mx-4 flex items-center justify-center
                 rounded-xl bg-gray-100 p-6 text-center dark:bg-gray-800"
      >
        <div className="h-100 flex flex-col justify-center gap-2">
          <QrCodeIcon className="w-20 self-center text-gray-500 dark:text-gray-400" />
          <Typography variant="h2">Scan Your Ticket</Typography>
          <Typography variant="body1">Please present your Ticket to check in</Typography>
        </div>
      </div>
    </div>
  );
}
