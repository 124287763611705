import {Registration} from '../../db/db';
import {ChoiceFieldProps} from './fields';

export function DataAreaClothingSize({registration}: {registration: Registration}) {
  let size = '';

  if (
    registration &&
    registration.registrationData &&
    Array.isArray(registration.registrationData)
  ) {
    for (const section of registration.registrationData) {
      if (Array.isArray(section.fields)) {
        for (const field of section.fields) {
          if (
            field.title === 'Kleidergröße Oberbekleidung' ||
            field.title === 'Clothing size Outerwear'
          ) {
            if (
              ((!registration.indicoId.includes('-') &&
                (section.title === 'My Registration' || section.title === 'Meine Anmeldung')) ||
                (registration.indicoId.includes('-') &&
                  (section.title === 'Accompanying Person' ||
                    section.title === 'Begleitperson'))) &&
              field.inputType === 'single_choice'
            ) {
              const choice_field = field as ChoiceFieldProps;

              const selected = Object.keys(choice_field.data)[0];
              if (selected !== undefined) {
                const caption = choice_field.choices.find(
                  choice => choice.id === selected
                )?.caption;
                if (caption !== undefined) {
                  size = caption;
                }
              }
            }
          }
        }
      }
    }
  }

  return (
    <div className="align-stretch flex flex-col flex-wrap justify-center gap-4 text-center md:flex-row">
      {size !== '' && (
        <div
          className={`rounded-xl border border-gray-500 bg-cyan-900 p-4 text-white dark:border-gray-700 md:px-8`}
        >
          {size}
        </div>
      )}
    </div>
  );
}
